<template>
  <div class="input-marker" :class="setLayoutColor">
    <input
      class="input-marker__field"
      :class="{ 'input-marker__field_error': error }"
      :type="type"
      :name="name"
      :value="value"
      :required="required"
      placeholder=" "
      v-model="valueInput"
      ref="inputMarker"
      @input="emitValue(valueInput)"
    />
    <label class="input-marker__label">
      {{ placeholder }}
      <span v-if="marker" class="input-marker__marker">
        {{ marker }}
      </span>
    </label>
  </div>
</template>

<script>
export default {
  name: "InputMarker",
  props: {
    type: {
      type: String,
      default: "text",
    },
    name: {
      type: String,
    },
    value: {
      type: String,
    },
    required: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    marker: {
      type: String,
    },
    error: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      valueInput: "",
    };
  },
  watch: {
    value: function () {
      if (this.value === null) {
        this.valueInput = "";
      } else {
        this.valueInput = this.value;
      }
    },
  },
  computed: {
    layoutColor() {
      return this.$store.getters.GET_LAYOUT_COLOR;
    },
    setLayoutColor() {
      return `input-marker_${this.layoutColor}`;
    },
  },
  methods: {
    emitValue() {
      let input = this.$refs.inputMarker;
      this.$emit("input", input.value);
    },
  },
};
</script>

<style lang="scss" scoped>
.input-marker {
  width: 100%;
  position: relative;
  margin-bottom: 20px;

  &_purple {
    & .input-marker__label {
      color: rgba(76, 65, 203, 0.5);
      border: 1px solid rgba(76, 65, 203, 0.5);
    }

    & .input-marker__field {
      color: $color-purple-20;
      border: 1px solid rgba(76, 65, 203, 0.5);

      &::placeholder {
        color: rgba(76, 65, 203, 0.5);
      }
    }
  }

  &_red {
    & .input-marker__label {
      color: rgba(176, 6, 51, 0.5);
      border: 1px solid rgba(176, 6, 51, 0.5);
    }

    & .input-marker__field {
      color: $color-red-20;
      border: 1px solid rgba(176, 6, 51, 0.5);

      &::placeholder {
        color: rgba(176, 6, 51, 0.5);
      }
    }
  }

  &__label {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    font-size: 14px;
    font-weight: 400;
    background-color: $color-white;
    border-radius: 10px;
    padding: 0 10px;
    transition: $transition-main;
    display: flex;
    align-items: center;
    pointer-events: none;
  }

  &__marker {
    color: $color-red-10;
    margin-left: 3px;
  }

  &__field {
    width: 100%;
    height: 48px;
    border-radius: 10px;
    padding: 0 10px;
    font-size: 14px;
    font-weight: 400;

    &::placeholder {
      font-size: 14px;
      font-weight: 400;
    }

    &_error {
      border: 1px solid $color-red-10;

      &:focus {
        border: 1px solid $color-red-10;
      }

      & + .input-marker__label {
        border: 1px solid $color-red-10;
      }
    }

    &:focus + .input-marker__label {
      display: none;
    }

    &:not(:placeholder-shown) + .input-marker__label {
      display: none;
    }
  }
}
</style>
