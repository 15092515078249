export default class Utils {
  static downloadFile(data, name) {
    const href = URL.createObjectURL(data);
    const link = document.createElement("a");
    link.href = href;
    link.setAttribute("download", `${name}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  }

  static isArray(val) {
    return Array.isArray(val);
  }

  static isPlainObject(val) {
    if (toString.call(val) !== "[object Object]") {
      return false;
    }
    const prototype = Object.getPrototypeOf(val);
    return prototype === null || prototype === Object.prototype;
  }

  static isFunction(val) {
    return toString.call(val) === "[object Function]";
  }
}
