<template>
  <div class="cookies" :class="setLayoutColor">
    <div class="cookies__banner">
      <div class="cookies__banner_text">
        Используя данный сайт, я выражаю свое согласие на обработку моих персональных данных.
        Пожалуйста, ознакомьтесь с политикой конфиденциальности на <a :href="url" target="_blank">странице</a>.
      </div>
      <div class="cookies__banner_button">
        <MainButton title="Принять" color="purple" @click="$emit('acceptCookies')" />
      </div>
    </div>
  </div>
</template>

<script>
import MainButton from "@/components/ui/Button/MainButton.vue";

export default {
  name: "CookieModal",
  components: {
    MainButton,
  },
  computed: {
    url() {
      return process.env.NODE_ENV === "production"
        ? "https://bonus.brixogroup.com/upload/pdf/Политика конфиденциальности на сайт BRIXO BONUS.pdf"
        : "http://brixo.tz365.ru/upload/pdf/Политика конфиденциальности на сайт BRIXO BONUS.pdf";
    },
    layoutColor() {
      return this.$store.getters.GET_LAYOUT_COLOR;
    },
    setLayoutColor() {
      return `cookies_${this.layoutColor}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.cookies {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 10%;
  left: 45%;
  z-index: 1000;

  &_purple {
    & .cookies__banner_text {
      color: $color-purple-40;
    }
  }

  &_red {
    & .cookies__banner_text {
      color: $color-red-20;
    }
  }

  &__banner {
    position: fixed;
    max-width: 80vw;
    width: 100%;
    padding: 20px 40px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.3);
    background-color: #fff;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;

    &_text {
      width: 80%;
      font-size: 14px;
      font-weight: 400;

      & a {
        color: $color-red;
      }
    }

    &_button {
      width: 150px;

      & .button {
        height: 48px;
      }
    }
  }
}
</style>
