import { render, staticRenderFns } from "./CheckboxBox.vue?vue&type=template&id=41651ae6&scoped=true&"
import script from "./CheckboxBox.vue?vue&type=script&lang=js&"
export * from "./CheckboxBox.vue?vue&type=script&lang=js&"
import style0 from "./CheckboxBox.vue?vue&type=style&index=0&id=41651ae6&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "41651ae6",
  null
  
)

export default component.exports