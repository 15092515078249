<template>
  <div class="switch-button">
    <input id="switch" class="switch-button__input" type="checkbox" v-model="checked" />
    <label class="switch-button__label" for="switch" @click="toggleTheme"></label>
  </div>
</template>

<script>
export default {
  name: "SwitchButton",
  data() {
    return {
      checked: false,
    };
  },
  mounted() {
    this.layoutColor === "red" ? (this.checked = true) : (this.checked = false);
  },
  computed: {
    layoutColor() {
      return this.$store.getters.GET_LAYOUT_COLOR;
    },
  },
  methods: {
    toggleTheme() {
      this.$store.dispatch("toggleLayoutColor");
    },
  },
};
</script>

<style lang="scss" scoped>
.switch-button {
  & input[type="checkbox"] {
    height: 0;
    width: 0;
    // visibility: hidden;
    display: none;
  }

  &__input {
    &:checked + .switch-button__label::after {
      left: calc(100% - 2px);
      transform: translate(-100%, -50%);
      background: $color-red-10;
    }
  }

  &__label {
    cursor: pointer;
    text-indent: -9999px;
    width: 60px;
    height: 28px;
    background: $color-white;
    display: block;
    border-radius: 100px;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      width: 24px;
      height: 24px;
      top: 50%;
      left: 2px;
      transform: translateY(-50%);
      background: $color-purple-10;
      border-radius: 90px;
      transition: 0.3s;
    }
  }
}

@media screen and (max-width: 360px) {
  .switch-button {
    &__label {
      width: 50px;
    }
  }
}
</style>
