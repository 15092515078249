export default {
    props: {
        multiple: {
            type: Boolean,
            default: false
        },
        maxMultiple: Number
    },

    data() {
        return {
            selectedMultiple: []
        }
    },

    computed: {
        getMultipleClass() {
            return  this.multiple ? 'select_multiple' : '';
        }
    },

    methods: {
        onSelectMultiple(evt, item) {
            let isChecked = evt.target.checked;

            if(isChecked) {
                this.selectedMultiple.push(item)
            } else {
                this.removeSelectedOption(item)
            }

            this.clearSearch();
            this.focusSearch();
            this.emit();
        },

        removeSelectedOption(item) {
            let index = this.selectedMultiple.indexOf(item)
            this.selectedMultiple.splice(index, 1);
            this.focusSearch();
        },

        isSelectedOption(payload) {
            return this.selectedMultiple.some(item => item.id === payload.id)
        },

        filterBySelected(options, optionsSelected) {
            return options.filter(option => !optionsSelected.find(({ id }) => option.id === id))
        }
    },
    watch: {
        selectedMultiple(val) {
            if(val.length >= this.maxMultiple) {
                this.showOptions = false
            }
        }
    }
}