<template>
  <div class="tab" v-show="isActive" :class="[getStyle, setLayoutColor]">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "TabItem",
  props: {
    title: {
      type: String,
    },
    isModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isActive: true,
    };
  },
  computed: {
    getStyle() {
      return this.isModal ? "tab_modal" : "";
    },
    layoutColor() {
      return this.$store.getters.GET_LAYOUT_COLOR;
    },
    setLayoutColor() {
      return `tab_${this.layoutColor}`;
    },
  },
};
</script>

<style lang="scss"></style>
