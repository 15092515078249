<template>
  <div class="tab-modal" :class="[setLayoutColor]">
    <TabContainer @click="$emit('reset')">
      <TabItem
        title="Ритейл"
        :isModal="true"
        v-if="(inviteInfo && inviteInfo.target_promo_group_system_name === 'member') || (referralInfo && role === 'member') || showBoth"
      >
        <div class="tab-modal__header">
          <slot name="header" />
          <div class="tab-modal__header_button">
            <MainButton
              v-if="layoutColor === 'purple'"
              :iconAfter="require('@/assets/images/close.svg')"
              :isIcon="true"
              @click="$emit('hideModal')"
            />
            <MainButton
              v-if="layoutColor === 'red'"
              :iconAfter="require('@/assets/images/close-red.svg')"
              :isIcon="true"
              @click="$emit('hideModal')"
            />
          </div>
        </div>
        <div class="tab-modal__content">
          <slot name="content-representative" />
        </div>
      </TabItem>
      <TabItem
        title="Сейлз"
        :isModal="true"
        v-if="(inviteInfo && inviteInfo.target_promo_group_system_name === 'manager') || (referralInfo && role === 'manager') || showBoth"
      >
        <div class="tab-modal__header">
          <slot name="header" />
          <div class="tab-modal__header_button">
            <MainButton
              v-if="layoutColor === 'purple'"
              :iconAfter="require('@/assets/images/close.svg')"
              :isIcon="true"
              @click="$emit('hideModal')"
            />
            <MainButton
              v-if="layoutColor === 'red'"
              :iconAfter="require('@/assets/images/close-red.svg')"
              :isIcon="true"
              @click="$emit('hideModal')"
            />
          </div>
        </div>
        <div class="tab-modal__content">
          <slot name="content-sales" />
        </div>
      </TabItem>
    </TabContainer>
  </div>
</template>

<script>
import TabContainer from "@/components/ui/Tabs/TabContainer.vue";
import TabItem from "@/components/ui/Tabs/TabItem.vue";
import MainButton from "@/components/ui/Button/MainButton.vue";

export default {
  name: "TabModal",
  components: {
    TabContainer,
    TabItem,
    MainButton,
  },
  props: {
    inviteInfo: {
      type: Object,
    },
    referralInfo: {
      type: Object,
    },
    showBoth: {
      type: Boolean,
      default: false,
    },
    role: String,
  },
  computed: {
    layoutColor() {
      return this.$store.getters.GET_LAYOUT_COLOR;
    },
    setLayoutColor() {
      return `tab-modal_${this.layoutColor}`;
    },
  },
};
</script>

<style lang="scss">
.tab-modal {
  max-height: 98vh;
  overflow-y: auto;
  position: fixed;
  max-width: 640px;
  width: 100%;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  transition: $transition-main;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &_purple {
    & .tab-modal__content_hint-text {
      background-color: $color-lilac;
      color: $color-purple-40;
    }

    & .tab-modal__content_input {
      border: 1px solid rgba(76, 65, 203, 0.5);
      color: $color-purple-50;

      &::placeholder {
        color: rgba(76, 65, 203, 0.5);
      }

      &_disable {
        border: 1px solid $color-red;

        &:focus {
          border: 1px solid $color-red;
        }
      }

      &_success {
        border: 1px solid $color-green;

        &:focus {
          border: 1px solid $color-green;
        }
      }
    }
  }

  &_red {
    & .tab-modal__content_hint-text {
      background-color: #ffeff3;
      color: $color-red-30;
    }

    & .tab-modal__content_input {
      border: 1px solid rgba(176, 6, 51, 0.5);
      color: $color-red-20;

      &::placeholder {
        color: rgba(176, 6, 51, 0.5);
      }

      &_disable {
        border: 1px solid $color-red;

        &:focus {
          border: 1px solid $color-red;
        }
      }

      &_success {
        border: 1px solid $color-green;

        &:focus {
          border: 1px solid $color-green;
        }
      }
    }
  }

  &__header {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &__header_button {
    width: 15px;
    height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;

    .button {
      padding: 0;
      border: none;
      background: none;
      cursor: pointer;
      transition: $transition-main;

      &:hover {
        transform: rotate(-180deg) scale(1.2);
        transition: $transition-main;
        background-color: transparent;
      }
    }
  }

  &__content {
    width: 100%;
    height: 100%;
    padding: 0 33px 33px 33px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &:last-child {
      margin-bottom: 10px;
    }

    &_message {
      display: flex;
      flex-direction: column;
    }

    &_header {
      max-width: 250px;
      width: 100%;
      font-weight: 700;
      font-size: 24px;
      margin-bottom: 30px;
    }

    &_img {
      max-width: 302px;
      width: 100%;
      height: 180px;
      border-radius: 10px;
      overflow: hidden;
      margin-bottom: 30px;

      & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &_text {
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin-bottom: 40px;

      &-email {
        color: $color-blue;
      }
    }

    &_subtitle {
      margin-bottom: 30px;
      font-weight: 700;
      font-size: 16px;
      display: flex;
      position: relative;
    }

    &_hint {
      &-img {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        width: 22px;
        height: 22px;
        cursor: pointer;
        margin-left: 10px;

        &:hover {
          transition: all 0.3s ease-in-out;

          ~ .tab-modal__content_hint-text {
            display: block;
            z-index: 3;
            transition: all 0.3s ease-in-out;
          }
        }
      }

      &-text {
        border-radius: 10px;
        padding: 10px 20px;
        display: none;
        position: absolute;
        left: 0;
        top: 130%;
        font-size: 14px;
        font-weight: 400;
        line-height: 26px;

        a {
          text-decoration: underline;
        }
      }
    }

    &_error {
      color: $color-red;
      margin-bottom: 20px;
    }

    &_form {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &_inputs {
      margin-bottom: 10px;
      display: flex;
      flex-direction: column;
    }

    &_row {
      position: relative;
      display: flex;
      justify-content: space-between;
      gap: 20px;
      margin-bottom: 10px;

      &-zero {
        margin-bottom: 0;
      }

      .tab-modal__content_hint-img {
        position: absolute;
        left: -26px;
        top: 13px;
        margin-left: 0;
      }
    }

    &_search {
      width: 100%;
      height: 48px;
      border: 1px solid #e0e0e0;
      border-radius: 10px;
      padding: 0 10px;
      @include font("Inter", 14px, 400);
      // color: $color-text;
      margin-bottom: 10px;

      &::placeholder {
        // color: $color-middle-grey;
        @include font("Inter", 14px, 400);
      }
    }

    &_input {
      max-width: 248px;
      width: 100%;
      height: 48px;
      border-radius: 10px;
      padding: 0 10px;
      font-size: 14px;
      font-weight: 400;

      &-width {
        max-width: 100%;
      }

      &::placeholder {
        font-size: 14px;
        font-weight: 400;
      }
    }

    &_agreement {
      display: flex;
      width: 100%;
      margin-bottom: 30px;
    }

    &_checkbox {
      max-width: 246px;
      width: 100%;
      margin-bottom: 30px;
      align-self: flex-end;
    }

    &_notification {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
      align-items: flex-start;

      & p {
        margin-bottom: 10px;
        @include font("Inter", 14px, 600);
      }
    }

    &_button {
      max-width: 250px;
      width: 100%;
      height: 48px;
      //margin-bottom: 20px;

      &-small {
        max-width: 250px;
        width: 100%;
        height: 48px;
        align-self: center;
      }
    }

    &_options {
      display: flex;
      justify-content: space-between;
    }
  }
}

@media screen and (max-width: 660px) {
  .tab-modal {
    max-width: 600px;

    &__content {
      padding: 0 13px;
    }
  }
}

@media screen and (max-width: 573px) {
  .tab-modal {
    max-width: 510px;

    &__content {
      &_row {
        flex-wrap: wrap;
        gap: 10px;

        &-zero {
          margin-bottom: 10px;
          gap: 0;
        }
      }

      &_input {
        max-width: 100%;
      }

      &_checkbox {
        align-self: flex-start;
      }

      &_button {
        max-width: 100%;
      }
    }
  }
}

@media screen and (max-width: 520px) {
  .tab-modal {
    max-width: 420px;

    &__content {
      &_hint {
        &-title {
          max-width: 330px;
          width: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 420px) {
  .tab-modal {
    max-width: 314px;

    &__content {
      &_hint {
        &-title {
          max-width: 233px;
          width: 100%;
        }
      }
    }
  }
}
</style>
