import api from "@/api";

const state = {
  reportList: [],
  reportDetails: [],
  totalPages: null,
};

const getters = {
  GET_REPORT_LIST(state) {
    return state.reportList;
  },
  GET_REPORT_DETAILS(state) {
    return state.reportDetails;
  },
  GET_TOTAL_PAGES(state) {
    return state.totalPages;
  },
};

const mutations = {
  SET_REPORT_LIST(state, payload) {
    state.reportList = payload;
  },
  SET_REPORT_DETAILS(state, payload) {
    state.reportDetails = payload;
  },
  SET_TOTAL_PAGES(state, paylaod) {
    state.totalPages = paylaod;
  },
};

const actions = {
  async fetchReportList({ commit }, payload) {
    commit({ type: "SET_PRELOADER_INCR" }, { root: true });
    let res = await api.reports.getReports(payload);
    if (res.isSuccess) {
      commit("SET_REPORT_LIST", res.result.data);
      commit("SET_TOTAL_PAGES", res.result.pagination.last_page);
    }
    commit({ type: "SET_PRELOADER_DECR" }, { root: true });
    return res;
  },
  async fetchReportDetails({ commit }, payload) {
    commit({ type: "SET_PRELOADER_INCR" }, { root: true });
    let res = await api.reports.getReportDetails(payload);
    if (res.isSuccess) {
      commit("SET_REPORT_DETAILS", res.result.data);
    }
    commit({ type: "SET_PRELOADER_DECR" }, { root: true });
    return res;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
