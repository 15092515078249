import api from "@/api";

const state = {
  menu: [],
  steps: [],
  rules: [],
  faq: [],
  info: [],
  gifts: [],
};

const getters = {
  GET_MENU(state) {
    return state.menu;
  },
  GET_STEPS(state) {
    return state.steps;
  },
  GET_RULES(state) {
    return state.rules;
  },
  GET_FAQ(state) {
    return state.faq;
  },
  GET_INFO(state) {
    return state.info;
  },
  GET_GIFTS(state) {
    return state.gifts;
  },
};

const mutations = {
  SET_MENU(state, payload) {
    state.menu = payload;
  },
  SET_STEPS(state, payload) {
    state.steps = payload;
  },
  SET_RULES(state, payload) {
    state.rules = payload;
  },
  SET_FAQ(state, payload) {
    state.faq = payload;
  },
  SET_INFO(state, payload) {
    state.info = payload;
  },
  SET_GIFTS(state, payload) {
    state.gifts = payload;
  },
};

const actions = {
  async getMenu({ commit }) {
    commit({ type: "SET_PRELOADER_INCR" }, { root: true });
    let res = await api.content.getMenu();
    if (res.isSuccess) {
      commit("SET_MENU", res.result);
    }
    commit({ type: "SET_PRELOADER_DECR" }, { root: true });
    return res;
  },
  async getSteps({ commit }) {
    commit({ type: "SET_PRELOADER_INCR" }, { root: true });
    let res = await api.content.getSteps();
    if (res.isSuccess) {
      commit("SET_STEPS", res.result);
    }
    commit({ type: "SET_PRELOADER_DECR" }, { root: true });
    return res;
  },
  async getRules({ commit }) {
    commit({ type: "SET_PRELOADER_INCR" }, { root: true });
    let res = await api.content.getRules();
    if (res.isSuccess) {
      commit("SET_RULES", res.result);
    }
    commit({ type: "SET_PRELOADER_DECR" }, { root: true });
    return res;
  },
  async getFAQ({ commit }) {
    commit({ type: "SET_PRELOADER_INCR" }, { root: true });
    let res = await api.content.getFAQ();
    if (res.isSuccess) {
      commit("SET_FAQ", res.result);
    }
    commit({ type: "SET_PRELOADER_DECR" }, { root: true });
    return res;
  },
  async getInfo({ commit }) {
    commit({ type: "SET_PRELOADER_INCR" }, { root: true });
    let res = await api.content.getInfo();
    if (res.isSuccess) {
      commit("SET_INFO", res.result);
    }
    commit({ type: "SET_PRELOADER_DECR" }, { root: true });
    return res;
  },
  async getGifts({ commit }) {
    commit({ type: "SET_PRELOADER_INCR" }, { root: true });
    let res = await api.content.getGifts();
    if (res.isSuccess) {
      commit("SET_GIFTS", res.result);
    }
    commit({ type: "SET_PRELOADER_DECR" }, { root: true });
    return res;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
