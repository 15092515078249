<template>
  <TabModal
      :inviteInfo="inviteInfo"
      :referralInfo="referralInfo"
      :showBoth="showBoth"
      :role="role"
      @hideModal="$emit('hideModal')"
      @reset="resetForm"
  >
    <template #content-representative>
      <div class="tab-modal__content_message">
        <div class="tab-modal__content_header">Регистрация</div>
        <div class="tab-modal__content_subtitle">
          <div class="tab-modal__content_hint-title">Ритейл (СТО, ОПТ, Розница, Интернет-магазин)</div>
          <div class="tab-modal__content_hint-img">
            <img src="@/assets/images/hint.svg"/>
          </div>
          <div class="tab-modal__content_hint-text">
            Ритейл – сотрудник/руководитель дилера, ритейлера, магазина, СТО, интернет-магазина, автосервиса или другое
            торговое
            предприятие, закупающее Продукцию у официального Дистрибьютора продукции брендов Sakura, SURE и brixo.
          </div>
        </div>
        <div v-if="error" class="tab-modal__content_error">
          {{ error }}
        </div>
        <form
            class="tab-modal__content_form"
            action=""
            enctype="multipart/form-data"
            ref="registerMember"
            @submit.prevent="registerMember"
            @input.prevent="checkForm"
            @change.prevent="checkForm"
        >
          <div class="tab-modal__content_inputs">
            <div class="tab-modal__content_row tab-modal__content_row-zero">
              <InputSearch
                  placeholder="ИНН"
                  name="company_inn"
                  id="company_inn"
                  required="true"
                  :setWidth="true"
                  v-model="registerForm.company_inn"
                  @input="findInn"
                  @legalData="insertCompanyData"
              />
              <input
                  class="tab-modal__content_input"
                  type="text"
                  name="legalName"
                  id="legalName"
                  v-model="registerForm.legalName"
                  placeholder="Юр. наименование организации"
                  disabled
              />
            </div>
            <div class="tab-modal__content_row">
              <input
                  class="tab-modal__content_input"
                  type="text"
                  name="legalAddress"
                  id="legalAddress"
                  v-model="registerForm.legalAddress"
                  placeholder="Юридический адрес"
                  disabled
              />
              <SelectInput
                  name="segment"
                  id="segment"
                  required="true"
                  :options="segments"
                  placeholder="Сегмент"
                  v-model="registerForm.company_segment_id"
                  @input="checkDealer($event)"
              />
            </div>
            <div class="tab-modal__content_row">
              <input
                  class="tab-modal__content_input"
                  type="text"
                  name="company_actual_name_organization"
                  id="company_actual_name_organization"
                  v-model="registerForm.company_actual_name_organization"
                  placeholder="Фактическое наименование"
                  required="true"
              />
              <!-- <input
                  class="tab-modal__content_input"
                  type="text"
                  name="company_actual_address_organization"
                  id="company_actual_address_organization"
                  v-model="registerForm.company_actual_address_organization"
                  placeholder="Фактический адрес"
                  required="true"
              /> -->
              <InputSearch
                placeholder="Фактический адрес"
                name="company_actual_address_organization"
                id="company_actual_address_organization"
                required="true"
                :setWidth="true"
                v-model="registerForm.company_actual_address_organization"
                @input="findRegion"
                @regionData="insertRegionData"
              />
            </div>
            <div class="tab-modal__content_checkbox">
              <CheckboxBox name="sameAddress" v-model="sameAddress" text="Совпадает с юридическим"/>
            </div>
            <div class="tab-modal__content_row">
              <input
                  class="tab-modal__content_input"
                  type="text"
                  name="last_name"
                  id="last_name"
                  v-model="registerForm.last_name"
                  placeholder="Фамилия"
                  required="true"
              />
              <input
                  class="tab-modal__content_input"
                  type="tel"
                  name="phone"
                  id="phone"
                  v-model="registerForm.phone"
                  placeholder="Телефон"
                  required="true"
                  @input="formatPhone"
              />
            </div>
            <div class="tab-modal__content_row">
              <input
                  class="tab-modal__content_input"
                  type="text"
                  name="first_name"
                  id="first_name"
                  v-model="registerForm.first_name"
                  placeholder="Имя"
                  required="true"
              />
              <input
                  class="tab-modal__content_input"
                  type="email"
                  name="email"
                  id="email"
                  v-model="registerForm.email"
                  placeholder="E-mail"
                  required="true"
              />
            </div>
            <div class="tab-modal__content_row">
              <input
                  class="tab-modal__content_input"
                  type="text"
                  name="sur_name"
                  id="sur_name"
                  v-model="registerForm.sur_name"
                  placeholder="Отчество"
                  required="true"
              />
              <input
                  class="tab-modal__content_input"
                  type="text"
                  name="employee_position"
                  id="employee_position"
                  v-model="registerForm.employee_position"
                  placeholder="Должность"
              />
            </div>
            <div v-if="!referralInfo && !inviteInfo" class="tab-modal__content_row">
              <div class="tab-modal__content_hint-img">
                <img src="@/assets/images/hint.svg"/>
              </div>
              <div class="tab-modal__content_hint-text">
                Если вы закупаете продукцию Sakura и NiBK у нескольких дистрибьюторов - добавьте их на вкладке «Профиль» - кнопка «Добавить дистрибьютора» в личном кабинете или обратитесь на почту
                <a href="mailto:bonus@brixogroup.com">bonus@brixogroup.com</a>
              </div>
              <SelectInput
                  placeholder="Дистрибьютор"
                  name="curator"
                  id="curator"
                  required="true"
                  v-model="registerForm.curator"
                  :options="curators"
                  width="max"
                  :searchable="true"
              />
            </div>
            <div v-if="!referralInfo && !inviteInfo" class="tab-modal__content_row">
              <input
                  class="tab-modal__content_input"
                  type="text"
                  name="manager"
                  id="manager"
                  v-model="registerForm.manager"
                  placeholder="Телефон сейлз-менеджера"
                  v-mask="'+7 (###) ###-##-##'"
                  @input="checkPhoneNum"
                  @paste.prevent
              />
              <div class="tab-modal__content_button">
                <MainButton
                    title="Проверить"
                    type="button"
                    color="form"
                    :isLoading="isLoading"
                    @click="findManagerPhone"
                />
              </div>
            </div>
            <div v-if="!referralInfo && !inviteInfo" class="tab-modal__content_agreement">
              <CheckboxBox name="agreementMember" v-model="agreement"
                           text="Согласен зарегистрироваться без сейлз-менеджера"/>
            </div>
            <div class="tab-modal__content_notification">
              <p>Способ получения уведомлений:</p>
              <CheckboxBox name="notificationMember" v-model="notification" text="Электронная почта"/>
            </div>
          </div>
          <div class="tab-modal__content_button-small">
            <MainButton
                title="Зарегистрироваться"
                type="submit"
                color="purple"
                :isLoading="isLoading"
                :isDisabled="disableRepresentativeBtn"
            />
          </div>
        </form>
      </div>
    </template>
    <template #content-sales>
      <div class="tab-modal__content_message">
        <div class="tab-modal__content_header">Регистрация</div>
        <div class="tab-modal__content_subtitle">
          <div class="tab-modal__content_hint-title">Сейлз-менеджер</div>
          <div class="tab-modal__content_hint-img">
            <img src="@/assets/images/hint.svg"/>
          </div>
          <div class="tab-modal__content_hint-text">
            Сейлз-менеджер – менеджер по продажам официального Дистрибьютора продукции брендов Sakura, SURE и brixo.
          </div>
        </div>
        <div v-if="error" class="tab-modal__content_error">
          {{ error }}
        </div>
        <form
            class="tab-modal__content_form"
            action=""
            enctype="multipart/form-data"
            ref="registerManager"
            @submit.prevent="registerManager"
            @input.prevent="checkForm"
        >
          <div class="tab-modal__content_inputs">
            <div v-if="!inviteInfo && !referralInfo" class="tab-modal__content_row">
              <SelectInput
                  id="curator"
                  placeholder="Дистрибьютор"
                  name="curator"
                  required="true"
                  v-model="registerForm.related_to_user_id"
                  :options="curators"
                  width="max"
                  :searchable="true"
              />
            </div>
            <div class="tab-modal__content_row">
              <input
                  id="last_name"
                  class="tab-modal__content_input"
                  type="text"
                  name="last_name"
                  v-model="registerForm.last_name"
                  placeholder="Фамилия"
                  required="true"
              />
              <input
                  id="phone"
                  class="tab-modal__content_input"
                  type="tel"
                  name="phone"
                  v-model="registerForm.phone"
                  placeholder="Телефон"
                  required="true"
                  @input="formatPhone"
              />
            </div>
            <div class="tab-modal__content_row">
              <input
                  id="first_name"
                  class="tab-modal__content_input"
                  type="text"
                  name="first_name"
                  v-model="registerForm.first_name"
                  placeholder="Имя"
                  required="true"
              />
              <input
                  id="email"
                  class="tab-modal__content_input"
                  type="email"
                  name="email"
                  v-model="registerForm.email"
                  placeholder="E-mail"
                  required="true"
              />
            </div>
            <div class="tab-modal__content_row">
              <input
                  id="sur_name"
                  class="tab-modal__content_input"
                  type="text"
                  name="sur_name"
                  v-model="registerForm.sur_name"
                  placeholder="Отчество"
                  required="true"
              />
            </div>
            <div class="tab-modal__content_notification">
              <p>Способ получения уведомлений:</p>
              <CheckboxBox name="notificationSales" v-model="notification" text="Электронная почта"/>
            </div>
          </div>
          <div class="tab-modal__content_button-small">
            <MainButton
                title="Зарегистрироваться"
                type="submit"
                color="purple"
                :isLoading="isLoading"
                :isDisabled="
                !registerForm.related_to_user_id ||
                !registerForm.first_name ||
                !registerForm.last_name ||
                !registerForm.sur_name ||
                !registerForm.phone ||
                !registerForm.email
              "
            />
          </div>
        </form>
      </div>
    </template>
  </TabModal>
</template>

<script>
import TabModal from "@/components/template/TabModal.vue";
import InputSearch from "@/components/ui/Input/InputSearch.vue";
import MainButton from "@/components/ui/Button/MainButton.vue";
import SelectInput from "@/components/ui/Select/SelectInput.vue";
import CheckboxBox from "@/components/ui/Checkbox/CheckboxBox.vue";

export default {
  name: "RegisterForm",
  components: {
    TabModal,
    InputSearch,
    MainButton,
    SelectInput,
    CheckboxBox,
  },
  props: {
    inviteInfo: {
      type: Object,
    },
    referralInfo: {
      type: Object,
    },
    inviteInfoID: {
      type: [String, Number],
    },
    showBoth: {
      type: Boolean,
      default: false,
    },
    role: String,
  },
  data() {
    return {
      registerForm: {
        company_inn: null,
        legalName: null,
        legalAddress: null,
        company_segment_id: null,
        segmentManual: null,
        company_actual_name_organization: null,
        company_actual_address_organization: null,
        first_name: null,
        last_name: null,
        sur_name: null,
        phone: null,
        email: null,
        employee_position: null,
        manager: null,
        managerID: null,
        curator: null,
        user_role: null,
        related_to_user_id: null,
        is_dealer: false,
      },
      sameAddress: false,
      agreement: false,
      notification: false,
      selectCurator: [],
      timer: undefined,
      segments: [],
      curators: [],
      isLoading: false,
    };
  },
  mounted() {
    this.init();
    this.fillForm();
  },
  watch: {
    sameAddress: function () {
      if (this.sameAddress === true) {
        this.registerForm.company_actual_address_organization = this.registerForm.legalAddress;
        document.getElementById("company_actual_address_organization").classList.remove("tab-modal__content_input_disable");
      } else {
        this.registerForm.company_actual_address_organization = null;
        document.getElementById("company_actual_address_organization").classList.add("tab-modal__content_input_disable");
      }
    },
    agreement: function () {
      if (this.agreement) {
        this.registerForm.manager = null;
        this.registerForm.managerID = null;
      }
      this.checkPhoneNum();
    },
  },
  computed: {
    error() {
      return this.$store.getters["user/GET_ERROR"];
    },
    disableRepresentativeBtn() {
      if (
          !this.registerForm.company_segment_id ||
          (!this.registerForm.related_to_user_id && !this.registerForm.curator) ||
          (!this.agreement && !this.registerForm.manager && !this.registerForm.related_to_user_id) ||
          !this.registerForm.company_inn ||
          !this.registerForm.company_actual_address_organization ||
          !this.registerForm.company_actual_name_organization ||
          !this.registerForm.first_name ||
          !this.registerForm.last_name ||
          !this.registerForm.sur_name ||
          !this.registerForm.email ||
          !this.registerForm.phone
      ) {
        return true;
      }
      return false;
    },
  },
  methods: {
    async init() {
      let segments = await this.$api.general.getSegments();
      segments.result.forEach((segment) => {
        this.segments.push({
          id: segment.id,
          name: segment.display_name,
        });
      });
      let curators = await this.$api.general.getCompaniesList();
      curators.result.forEach((curator) => {
        this.curators.push({
          id: curator.curator_id,
          name: curator.actual_name_organization,
        });
      });
    },
    fillForm() {
      if (this.inviteInfo) {
        this.registerForm.email = this.inviteInfo.email;
        this.registerForm.first_name = this.inviteInfo.first_name;
        this.registerForm.last_name = this.inviteInfo.last_name;
        this.registerForm.sur_name = this.inviteInfo.sur_name;
        this.registerForm.user_role = this.inviteInfo.target_promo_group_system_name;
        this.registerForm.related_to_user_id = this.inviteInfo.user_id;
      } else if (this.referralInfo) {
        this.registerForm.related_to_user_id = this.referralInfo.id;
      }
    },
    async registerMember() {
      let phoneClean = this.registerForm.phone.replace(/[^A-Z0-9]/gi, "");
      let payload = {
        user_role: this.registerForm.user_role || "member",
        company_segment_id: this.registerForm.company_segment_id,
        company_actual_name_organization: this.registerForm.company_actual_name_organization,
        company_actual_address_organization: this.registerForm.company_actual_address_organization,
        company_inn: this.registerForm.company_inn,
        phone: phoneClean,
        email: this.registerForm.email,
        first_name: this.registerForm.first_name,
        last_name: this.registerForm.last_name,
        sur_name: this.registerForm.sur_name,
        employee_position: this.registerForm.employee_position,
        is_dealer: this.registerForm.is_dealer,
      };
      if (this.registerForm.manager) {
        payload["related_to_user_id"] = this.registerForm.managerID;
      } else {
        payload["related_to_user_id"] = this.registerForm.curator;
      }
      if (this.inviteInfoID) {
        payload["invite_id"] = this.inviteInfoID;
        payload["related_to_user_id"] = this.registerForm.related_to_user_id;
      }
      if (this.referralInfo) {
        payload["related_to_user_id"] = this.registerForm.related_to_user_id;
        payload["referral_from_user_id"] = this.registerForm.related_to_user_id;
      }
      await this.$store.dispatch("user/register", payload);
      if (this.error === null) {
        this.$emit("toActivation", payload);
        this.resetForm();
      }
    },
    async registerManager() {
      this.isLoading = true;
      let phoneClean = this.registerForm.phone.replace(/[^A-Z0-9]/gi, "");
      let payload = {
        user_role: this.registerForm.user_role || "manager",
        phone: phoneClean,
        email: this.registerForm.email,
        first_name: this.registerForm.first_name,
        last_name: this.registerForm.last_name,
        sur_name: this.registerForm.sur_name,
        related_to_user_id: this.registerForm.related_to_user_id,
      };
      if (this.inviteInfoID) {
        payload["invite_id"] = this.inviteInfoID;
      }
      if (this.referralInfo) {
        payload["referral_from_user_id"] = this.registerForm.related_to_user_id;
      }
      await this.$store.dispatch("user/register", payload);
      if (this.error === null) {
        this.$emit("toActivation", payload);
        this.resetForm();
      }
      this.isLoading = false
    },
    findInn() {
      let inn = this.registerForm.company_inn;
      if (inn) {
        clearTimeout(this.timer);

        this.timer = setTimeout(() => {
          this.$store.dispatch("general/findCompanyByInn", inn);
        }, 500);
      }
    },
    insertCompanyData(args) {
      this.registerForm.legalName = args.name;
      this.registerForm.legalAddress = args.address;
    },
    findRegion() {
      let region = this.registerForm.company_actual_address_organization;
      if (region) {
        clearTimeout(this.timer);

        this.timer = setTimeout(() => {
          this.$store.dispatch("general/findRegion", region);
        }, 500);
      }
    },
    insertRegionData(args) {
      this.registerForm.company_actual_address_organization = args;
    },
    checkPhoneNum() {
      let managerPhone = document.getElementById("manager");
      let reg = /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/;
      if (reg.test(this.registerForm.manager)) {
        managerPhone.classList.remove("tab-modal__content_input_disable");
        return false;
      } else {
        if (this.agreement) {
          managerPhone.classList.remove("tab-modal__content_input_success");
          managerPhone.classList.remove("tab-modal__content_input_disable");
          return true;
        } else {
          managerPhone.classList.remove("tab-modal__content_input_success");
          managerPhone.classList.add("tab-modal__content_input_disable");
          return true;
        }
      }
    },
    async findManagerPhone() {
      this.isLoading = true;
      let phoneClean = this.registerForm.manager.replace(/[^A-Z0-9]/gi, "");
      let phoneInput = document.getElementById("manager");
      let res = await this.$api.general.getManagerByPhone(phoneClean);
      if (res.isSuccess) {
        this.registerForm.managerID = res.result.id;
        phoneInput.classList.remove("tab-modal__content_input_disable");
        phoneInput.classList.add("tab-modal__content_input_success");
      } else {
        phoneInput.classList.remove("tab-modal__content_input_success");
        phoneInput.classList.add("tab-modal__content_input_disable");
        this.agreement = true;
      }
      this.isLoading = false;
    },
    checkForm() {
      let inputs = document.querySelectorAll(".tab-modal__content_input");
      inputs.forEach((input) => {
        if (input.hasAttribute("required")) {
          let id = input.getAttribute("id");
          let reg = /^(?!\s*$).+/;
          if (id === "phone") {
            reg = /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/;
          } else if (id === "email") {
            // eslint-disable-next-line no-useless-escape
            reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,8})+$/;
          } else if (id === "company_inn") {
            reg = /^([0-9]{10}|[0-9]{12})$/;
          }
          if (reg.test(input.value)) {
            input.classList.remove("tab-modal__content_input_disable");
          } else {
            input.classList.add("tab-modal__content_input_disable");
          }
        }
      });
      let selects = document.querySelectorAll(".select");
      selects.forEach((select) => {
        let id = select.getAttribute("id");
        if (id === "segment") {
          if (this.registerForm.company_segment_id === null) {
            select.classList.add("select_disable");
          } else {
            select.classList.remove("select_disable");
          }
        }
        if (id === "curator") {
          if (this.registerForm.curator === null) {
            select.classList.add("select_disable");
          } else {
            select.classList.remove("select_disable");
          }
        }
      });
      let tin = document.getElementById("company_inn");
      if (tin) {
        if (this.registerForm.company_inn === null) {
          tin.classList.add("input-search__field_disable");
        } else {
          tin.classList.remove("input-search__field_disable");
        }
      }
    },
    resetForm() {
      this.registerForm.company_inn =
          this.registerForm.legalName =
              this.registerForm.legalAddress =
                  this.registerForm.company_segment_id =
                      this.registerForm.segmentManual =
                          this.registerForm.company_actual_name_organization =
                              this.registerForm.company_actual_address_organization =
                                  this.registerForm.first_name =
                                      this.registerForm.last_name =
                                          this.registerForm.sur_name =
                                              this.registerForm.phone =
                                                  this.registerForm.email =
                                                      this.registerForm.employee_position =
                                                          this.registerForm.manager =
                                                              this.registerForm.curator =
                                                                  this.registerForm.user_role =
                                                                      null;
      this.sameAddress = this.agreement = this.notification = this.registerForm.is_dealer = false;
    },
    formatPhone(event) {
      let blank = "+_ (___) ___-__-__";
      let i = 0;
      let val = event.target.value.replace(/\D/g, "").replace(/^\d/, "7");
      this.registerForm.phone = blank.replace(/./g, function (char) {
        if (/[_\d]/.test(char) && i < val.length) return val.charAt(i++);

        return i >= val.length ? "" : char;
      });
    },
    checkDealer(segment) {
      this.registerForm.is_dealer = segment === 4;
    }
  },
};
</script>
