<template>
  <div class="checkbox" :class="[isLeft, setLayoutColor]">
    <div class="checkbox__item">
      <label :for="name">
        <input
          class="checkbox__input"
          type="checkbox"
          :name="name"
          :id="name"
          :value="value"
          v-model="checked"
          @change="$emit('input', checked)"
        />
        <span class="checkbox__point"></span>
        <div class="checkbox__text">
          {{ text }}
        </div>
      </label>
    </div>
  </div>
</template>

<script>
export default {
  name: "CheckboxBox",
  props: {
    text: {
      type: String,
    },
    name: {
      type: String,
    },
    value: {
      type: Boolean,
    },
    justifyLeft: {
      type: Boolean,
    },
  },
  data() {
    return {
      checked: false,
    };
  },
  watch: {
    value: function () {
      if (this.value === false) {
        this.checked = false;
      } else if (this.value === true && this.name === "agreementMember") {
        this.checked = true;
      }
    },
  },
  computed: {
    isLeft() {
      return this.justifyLeft ? "checkbox-left" : "";
    },
    layoutColor() {
      return this.$store.getters.GET_LAYOUT_COLOR;
    },
    setLayoutColor() {
      return `checkbox_${this.layoutColor}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.checkbox {
  display: flex;
  justify-content: flex-end;

  &_purple {
    & .checkbox__point {
      border: 1px solid $color-purple-20;
    }

    & .checkbox__text {
      color: $color-purple-50;
    }

    & label input:checked ~ .checkbox__point {
      background-color: $color-purple-20;
      border: 1px solid $color-purple-20;
    }
  }

  &_red {
    & .checkbox__point {
      border: 1px solid $color-red-10;
    }

    & .checkbox__text {
      color: $color-red-10;
    }

    & label input:checked ~ .checkbox__point {
      background-color: $color-red-10;
      border: 1px solid $color-red-10;
    }
  }

  &-left {
    justify-content: flex-start;
  }

  label {
    display: block;
    cursor: pointer;
  }

  &__item {
    max-width: 428px;
    width: 100%;
    padding-left: 30px;
    position: relative;
  }

  &__input {
    position: absolute;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(0 0 0 0);
  }

  &__point {
    width: 18px;
    height: 18px;
    border-radius: 4px;
    position: absolute;
    left: 0;
    top: 0;
    transition: $transition-main;
  }

  &__point:before {
    display: none;
    content: url("@/assets/images/check-white.svg");
    position: absolute;
    left: 2px;
    bottom: -2px;
  }

  &__text {
    width: 100%;
    font-size: 14px;
    font-weight: 400;
  }
}

// label input:checked ~ .checkbox__point {
//   background-color: $color-purple-20;
//   border: 1px solid $color-purple-20;
// }

label input:checked ~ .checkbox__point:before {
  display: block;
}
</style>
