export default {
    props: {
        searchable: {
            type: Boolean,
            default: false
        },
        searchPlaceholder: {
            type: String,
            default: 'Введите запрос...'
        },
        notFoundText: {
            type: String,
            default: 'Результат не найден'
        }
    },
    data () {
        return {
            searching: false,
            searchQuery: ''
        }
    },
    computed: {
        getSearchableClass() {
            return  this.searchable ? 'select_searchable' : '';
        }
    },
    methods: {
        focusSearch() {
            if (this.searchable) {
                setTimeout(() => this.$refs.searchInput.focus());
            }
        },
        clearSearch() {
            this.searchQuery = ''
        },
        filterByQuery(options = [], query = '') {
            if (!options.length || !query) return []

            return options.filter(option => {
                let name = option.name || ''
                let nameLowerCase = name.toLowerCase(),
                    queryLowerCase = query.toLowerCase();

                if(nameLowerCase.includes(queryLowerCase)) {
                    return option
                }
            })
        }
    },
    watch: {
        showOptions (val) {
            if(!this.searchable) return

            if(val) {
                this.searching = true;
                this.focusSearch();
            } else {
                this.searching = false;
            }
        },
    }
}