import Vue from "vue";
import Vuex from "vuex";
import user from "./modules/user";
import reports from "./modules/reports";
import general from "./modules/general";
import content from "./modules/content";
import prize from "./modules/prize";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    preloader: 0,
    layoutColor: JSON.parse(localStorage.getItem("theme")) || "purple",
  },
  getters: {
    GET_PRELOADER(state) {
      return state.preloader;
    },
    GET_LAYOUT_COLOR(state) {
      return state.layoutColor;
    },
  },
  mutations: {
    SET_LAYOUT_COLOR(state, payload) {
      localStorage.setItem("theme", JSON.stringify(payload));
      state.layoutColor = payload;
    },
    SET_PRELOADER_INCR(state) {
      state.preloader++;
    },
    SET_PRELOADER_DECR(state) {
      state.preloader--;
    },
  },
  actions: {
    initMainPage({ commit, dispatch }) {
      dispatch("preloaderOn");
      if (localStorage.auth) {
        commit("user/SET_IS_AUTH", JSON.parse(localStorage.getItem("auth")));
        commit("user/SET_USER_ID", JSON.parse(localStorage.getItem("userID")));
        commit("user/SET_USER_ROLE", JSON.parse(localStorage.getItem("userRole")));
        dispatch("user/fetchUser");
      } else {
        dispatch("content/getRules");
        dispatch("content/getFAQ");
        dispatch("content/getGifts");
      }
      dispatch("preloaderOff");
    },
    toggleLayoutColor({ commit, state }) {
      state.layoutColor === "purple" ? commit("SET_LAYOUT_COLOR", "red") : commit("SET_LAYOUT_COLOR", "purple");
    },
    preloaderOn({ commit }) {
      commit("SET_PRELOADER_INCR");
    },
    preloaderOff({ commit }) {
      commit("SET_PRELOADER_DECR");
    },
  },
  modules: {
    user,
    general,
    content,
    prize,
    reports,
  },
});
