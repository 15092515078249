<template>
  <ModalBox :hideClose="true">
    <template #content>
      <div class="modal__content_message">
        <div class="modal__content_header modal__content_header_purple">Активация учетной записи</div>
        <div class="modal__content_info">
          На ваш номер телефона отправлен код подтверждения. Он придет в течение одной минуты. После его ввода на ваш электронный адрес
          поступит логин и пароль для входа на сайт. <br />
          Если sms не поступит, обратитесь на почту обратной связи
          <a href="mailto:bonus@brixogroup.com" class="modal__content_info-email">bonus@brixogroup.com</a>.
        </div>
        <div v-if="error" class="modal__content_error">
          {{ error }}
        </div>
        <form action="" enctype="multipart/form-data" ref="activateForm" @submit.prevent="activate">
          <div class="modal__content_inputs">
            <input
              class="modal__content_input"
              type="text"
              name="code"
              id="code"
              v-model="activation.code"
              placeholder="Введите цифры из СМС"
              required="true"
            />
          </div>
          <div class="modal__content_button">
            <MainButton
              title="Активировать"
              type="submit"
              color="purple"
              :isDisabled="!(activation.code.length === 4)"
              :isLoading="isLoading"
            />
          </div>
        </form>
      </div>
    </template>
  </ModalBox>
</template>

<script>
import ModalBox from "@/components/template/ModalBox.vue";
import MainButton from "@/components/ui/Button/MainButton";

export default {
  name: "ActivationForm",
  components: {
    ModalBox,
    MainButton,
  },
  props: {
    phone: String,
  },
  data() {
    return {
      activation: {
        code: "",
      },
      error: null,
      isLoading: false,
    };
  },
  methods: {
    async activate() {
      this.isLoading = true;
      let codeInput = document.getElementById("code");
      let payload = {
        code: this.activation.code,
        phone: this.phone,
      };
      let res = await this.$api.user.activatePhone(payload);
      if (res.isSuccess) {
        this.$emit("showNotification");
        this.$emit("hideModal");
        codeInput.classList.remove("modal__content_input_disable");
        this.resetForm();
      } else {
        codeInput.classList.add("modal__content_input_disable");
        this.error = res.error.getMessage();
      }
      this.isLoading = false;
    },
    resetForm() {
      this.activation.code = null;
    },
  },
};
</script>
