import network from "@/api/network";

export default class User {
  static async login(data) {
    return await network.post("login", data, {});
  }

  static async register(data) {
    return await network.post("register", data, {});
  }

  static async logout() {
    return await network.post("user/logout/device", null, {});
  }

  static async recoverPassword(data) {
    return await network.post("restore/password/send/link", data, {});
  }

  static async changePassword(data) {
    return await network.post("restore/password", data, {});
  }

  static async getUserProfile() {
    return await network.get("user/profile", null, {});
  }

  static async acceptAgreement() {
    return await network.post("user/accept/agreement", null, {});
  }

  static async activatePhone(data) {
    return await network.post("confirm/phone", data, {});
  }

  static async requestSmsCode() {
    return await network.get("user/sms", null, {});
  }
}
