<template>
  <div class="textarea" :class="setLayoutColor">
    <textarea
      class="textarea__field"
      :name="name"
      placeholder=" "
      v-model="valueInput"
      ref="textarea"
      @input="emitValue(valueInput)"
    ></textarea>
    <div class="textarea__label">
      {{ placeholder }}
      <span v-if="marker" class="textarea__marker">
        {{ marker }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "TextArea",
  props: {
    name: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    marker: {
      type: String,
    },
    value: {
      type: String,
    },
  },
  data() {
    return {
      valueInput: "",
    };
  },
  watch: {
    value: function () {
      if (this.value === null) {
        this.valueInput = "";
      }
    },
  },
  computed: {
    layoutColor() {
      return this.$store.getters.GET_LAYOUT_COLOR;
    },
    setLayoutColor() {
      return `textarea_${this.layoutColor}`;
    },
  },
  methods: {
    emitValue() {
      let textarea = this.$refs.textarea;
      this.$emit("input", textarea.value);
    },
  },
};
</script>

<style lang="scss" scoped>
.textarea {
  position: relative;

  &_purple {
    & .textarea__label {
      color: rgba(76, 65, 203, 0.5);
      border: 1px solid rgba(76, 65, 203, 0.5);
    }

    & .textarea__field {
      color: $color-purple-20;
      border: 1px solid rgba(76, 65, 203, 0.5);

      &::placeholder {
        color: rgba(76, 65, 203, 0.5);
      }
    }
  }

  &_red {
    & .textarea__label {
      color: rgba(176, 6, 51, 0.5);
      border: 1px solid rgba(176, 6, 51, 0.5);
    }

    & .textarea__field {
      color: $color-red-20;
      border: 1px solid rgba(176, 6, 51, 0.5);

      &::placeholder {
        color: rgba(176, 6, 51, 0.5);
      }
    }
  }

  &__field {
    width: 100%;
    height: 134px;
    border-radius: 10px;
    padding: 10px;
    font-size: 14px;
    font-weight: 400;
    resize: none;

    &::placeholder {
      font-size: 14px;
      font-weight: 400;
    }

    &:focus + .textarea__label {
      display: none;
    }

    &:not(:placeholder-shown) + .textarea__label {
      display: none;
    }
  }

  &__label {
    font-size: 14px;
    font-weight: 400;
    background-color: $color-white;
    border-radius: 10px;
    padding: 10px;
    transition: $transition-main;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }

  &__marker {
    color: $color-red-10;
  }
}
</style>
