<template>
  <div class="download-link">
    <a :href="href" target="_blank">
      <div class="download-link__inner">
        <div class="download-link__icon">
          <img :src="src" />
        </div>
        <div class="download-link__text" :class="[setLayoutColor, getColor]">
          {{ text }}
        </div>
      </div>
    </a>
  </div>
</template>

<script>
export default {
  name: "DownloadLink",
  props: {
    src: {
      type: String,
    },
    text: {
      type: String,
    },
    href: {
      type: String,
    },
    color: {
      type: String,
    },
  },
  computed: {
    layoutColor() {
      return this.$store.getters.GET_LAYOUT_COLOR;
    },
    setLayoutColor() {
      return `download-link__text_layout_${this.layoutColor}`;
    },
    getColor() {
      return `download-link__text_${this.color}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.download-link {
  &__inner {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__text {
    &_layout_purple {
      &.download-link__text_yellow {
        color: $color-yellow;
      }

      &.download-link__text_purple {
        color: $color-purple-20;
      }
    }

    &_layout_red {
      &.download-link__text_yellow {
        color: $color-dark-yellow;
      }

      &.download-link__text_purple {
        color: $color-red-30;
      }
    }

    &_white {
      color: $color-white;
    }

    &_yellow {
      font-weight: 700;
      text-transform: uppercase;
    }
  }
}
</style>
