<template>
  <ModalBox @hideModal="$emit('hideModal')">
    <template #content>
      <div class="modal__content_message">
        <div class="modal__content_header modal__content_header_purple">Восстановление пароля</div>
        <div v-if="error" class="modal__content_error">
          {{ error }}
        </div>
        <div v-if="checkword && email">
          <form action="" enctype="multipart/form-data" ref="changePasswordForm" @submit.prevent="changePassword">
            <div class="modal__content_inputs">
              <input
                class="modal__content_input"
                type="password"
                name="password"
                id="password"
                v-model="changePasswordForm.password"
                placeholder="Новый пароль"
                required="true"
              />
              <input
                class="modal__content_input"
                type="password"
                name="confirm_password"
                id="confirm_password"
                v-model="changePasswordForm.confirm_password"
                placeholder="Повторите пароль"
                required="true"
              />
            </div>
            <div class="modal__content_button modal__content_button-mb">
              <MainButton
                title="Восстановить"
                :isDisabled="
                  !(
                    changePasswordForm.password &&
                    changePasswordForm.confirm_password &&
                    changePasswordForm.password.length >= 9 &&
                    changePasswordForm.confirm_password.length >= 9 &&
                    changePasswordForm.password === changePasswordForm.confirm_password
                  )
                "
                type="submit"
                color="purple"
                :isLoading="isLoading"
              />
            </div>
          </form>
        </div>
        <div v-else>
          <div v-if="success" class="modal__content_success">Письмо с данными для восстановления пароля отправлено на Вашу почту</div>
          <form action="" enctype="multipart/form-data" ref="recoverForm" @submit.prevent="recover" @input.prevent="checkEmail">
            <div class="modal__content_inputs modal__content_inputs-sm">
              <input
                class="modal__content_input"
                type="email"
                name="email"
                id="email"
                v-model="passwordForm.email"
                placeholder="E-mail"
                required="true"
              />
            </div>
            <div class="modal__content_button modal__content_button-mb">
              <MainButton title="Восстановить" type="submit" color="purple" :isLoading="isLoading"/>
            </div>
          </form>
        </div>
        <div class="modal__content_options">
          <div>
            <MainButton title="Авторизация" color="transparent" @click="$emit('toLogin')" />
          </div>
        </div>
      </div>
    </template>
  </ModalBox>
</template>

<script>
import ModalBox from "@/components/template/ModalBox.vue";
import MainButton from "@/components/ui/Button/MainButton.vue";

export default {
  name: "PasswordForm",
  components: {
    ModalBox,
    MainButton,
  },
  props: {
    checkword: {
      type: String,
    },
    email: {
      type: String,
    },
  },
  data() {
    return {
      error: null,
      success: false,
      passwordForm: {
        email: null,
      },
      changePasswordForm: {
        password: null,
        confirm_password: null,
      },
      isLoading: false,
    };
  },
  methods: {
    async recover() {
      this.isLoading = true;
      let emailInput = document.getElementById("email");
      let payload = { login: this.passwordForm.email };
      let res = await this.$api.user.recoverPassword(payload);
      if (res.isSuccess) {
        this.success = true;
        setTimeout(() => {
          this.resetForm();
          emailInput.classList.remove("modal__content_input_disable");
          this.$emit("hideModal");
          this.success = false;
        }, "2000");
      } else {
        emailInput.classList.add("modal__content_input_disable");
      }
      this.isLoading = false;
    },
    async changePassword() {
      this.isLoading = true;
      let payload = {
        check_word: this.checkword,
        email: this.email,
        new_password: this.changePasswordForm.confirm_password,
      };
      let res = await this.$api.user.changePassword(payload);
      if (res.isSuccess) {
        this.resetForm();
        this.$emit("hideModal");
      } else {
        this.error = res.error.getMessage();
      }
      this.isLoading = false;
    },
    checkEmail(event) {
      let input = event.target;
      // eslint-disable-next-line no-useless-escape
      let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,8})+$/;
      if (reg.test(input.value)) {
        input.classList.remove("modal__content_input_disable");
      } else {
        input.classList.add("modal__content_input_disable");
      }
    },
    resetForm() {
      this.passwordForm.email = null;
      this.changePasswordForm.password = this.changePasswordForm.confirm_password = null;
    },
  },
};
</script>
