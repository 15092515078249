<template>
  <ModalBox @hideModal="closeFeedback" size="full">
    <template #content>
      <div class="modal__content_message" v-if="!showSuccessMessage">
        <div class="modal__content_header modal__content_header_purple">Задать вопрос</div>
        <div class="modal__content_subtitle modal__content_subtitle_purple">
          Здесь вы можете задать<br />
          интересующий вас вопрос
        </div>
        <div class="modal__content_error" v-if="error" v-html="error"></div>
        <div>
          <form
            action=""
            id="feedback"
            enctype="multipart/form-data"
            ref="feedback"
            @input.prevent="checkForm"
            @submit.prevent="sendFeedback"
          >
            <div class="modal__content_inputs modal__content_inputs-zero">
              <InputMarker name="name" placeholder="ФИО" marker="*" v-model="feedbackForm.name" ref="name" />
              <div class="modal__content_error modal__content_error-email" v-if="emailError">Введите корректный email</div>
              <InputMarker
                type="email"
                name="email"
                placeholder="E-mail"
                marker="*"
                :error="emailError"
                v-model="feedbackForm.email"
                ref="email"
                @input="checkEmail"
              />
            </div>
            <div class="modal__content_textarea">
              <TextArea name="message" placeholder="Ваше сообщение" marker="*" v-model="feedbackForm.message" ref="message" />
            </div>
            <div class="modal__content_button modal__content_button-feedback">
              <MainButton
                  title="Отправить"
                  color="purple"
                  :isLoading="isLoading"
              />
            </div>
            <div class="modal__content_form-hint">
              <span class="modal__content_form-hint-colored">*</span> - поля обязательные для заполнения
            </div>
          </form>
        </div>
      </div>
      <div class="modal__content_message" v-else>
        <div class="modal__content_header modal__content_header_purple">Спасибо</div>
        <div class="modal__content_subtitle modal__content_subtitle_purple">
          Письмо отправлено, в ближайшее время наши менеджеры ответят вам.
        </div>
        <div class="modal__content_button">
          <MainButton title="Закрыть" @click="closeFeedback" color="purple" />
        </div>
      </div>
    </template>
  </ModalBox>
</template>

<script>
import ModalBox from "@/components/template/ModalBox.vue";
import MainButton from "@/components/ui/Button/MainButton.vue";
import InputMarker from "@/components/ui/Input/InputMarker.vue";
import TextArea from "@/components/ui/Input/TextArea.vue";

export default {
  name: "FeedbackForm",
  components: {
    ModalBox,
    MainButton,
    InputMarker,
    TextArea,
  },
  data() {
    return {
      feedbackForm: {
        name: null,
        email: null,
        message: null,
      },
      buttonDisabled: true,
      emailError: false,
      showSuccessMessage: false,
      error: null,
      isLoading: false,
    };
  },
  computed: {
    userProfile() {
      return this.$store.getters["user/GET_CURRENT_USER"];
    },
  },
  watch: {
    userProfile: function () {
      this.fillFeedbackForm();
    },
  },
  methods: {
    fillFeedbackForm() {
      if (this.userProfile) {
        this.feedbackForm.name = `${this.userProfile.last_name || ""} ${this.userProfile.first_name || ""} ${
          this.userProfile.sur_name || ""
        }`;
        this.feedbackForm.email = this.userProfile.email;
      } else {
        this.feedbackForm.name = this.feedbackForm.email = null;
      }
    },
    checkForm() {
      if (this.feedbackForm.name && this.feedbackForm.email && this.feedbackForm.message && !this.emailError) {
        this.buttonDisabled = false;
      } else {
        this.buttonDisabled = true;
      }
    },
    checkEmail(arg) {
      // eslint-disable-next-line no-useless-escape
      let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,8})+$/;
      if (reg.test(arg)) {
        this.emailError = false;
      } else {
        this.emailError = true;
      }
    },
    resetForm() {
      this.feedbackForm.name = this.feedbackForm.email = this.feedbackForm.message = null;
    },
    async sendFeedback() {
      this.isLoading = true;
      this.error = null;
      let payload = this.feedbackForm;
      let res = await this.$api.content.sendFeedback(payload);
      if (res.isSuccess) {
        this.showSuccessMessage = true;
      } else {
        this.error = res.error.getMessage();
      }
      this.isLoading = false;
    },
    closeFeedback() {
      this.$emit("hideFeedback");
      this.showSuccessMessage = false;
      this.resetForm();
    },
  },
};
</script>
