import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  // PUBLIC
  {
    path: "/",
    name: "Home",
    component: () => import("@/views/HomeView.vue"),
    meta: { requiresGuest: true },
  },
  {
    path: "/rules/",
    name: "Rules",
    component: () => import("@/views/Public/RulesView.vue"),
    meta: { requiresGuest: true },
  },
  {
    path: "/faq/",
    name: "FAQ",
    component: () => import("@/views/Public/FAQ.vue"),
    meta: { requiresGuest: true },
  },
  {
    path: "/brands/",
    name: "Brands",
    component: () => import("@/views/Public/BrandsView.vue"),
    meta: { requiresGuest: true },
  },
  {
    path: "/news/",
    name: "News",
    props: (route) => ({ query: route.query.page }),
    component: () => import("@/views/Public/NewsView.vue"),
  },
  {
    path: "/news/:id",
    name: "DetailNews",
    props: true,
    component: () => import("@/views/Public/NewsDetailView.vue"),
  },
  {
    path: "/promo/",
    name: "Promo",
    props: (route) => ({ query: route.query.page }),
    component: () => import("@/views/Public/PromoView.vue"),
  },
  {
    path: "/promo/:id",
    name: "DetailPromo",
    props: true,
    component: () => import("@/views/Public/PromoDetailView.vue"),
  },
  // DASHBOARD
  {
    path: "/dashboard/profile/",
    name: "profile",
    component: () => import("../views/Dashboard/ProfileView"),
    meta: { requiresAuth: true, roles: ["curator", "manager", "member"] },
  },
  {
    path: "/dashboard/report/",
    name: "report",
    component: () => import("../views/Dashboard/ReportView"),
    meta: { requiresAuth: true, roles: ["curator"] },
  },
  {
    path: "/dashboard/invite/",
    name: "invite",
    component: () => import("../views/Dashboard/InviteView"),
    meta: { requiresAuth: true, roles: ["curator", "manager"] },
  },
  // {
  //   path: "/dashboard/sales/",
  //   name: "sales",
  //   component: () => import("../views/Dashboard/SalesView"),
  //   meta: { requiresAuth: true, roles: ["manager"] },
  // },
  {
    path: "/dashboard/purchase/",
    name: "purchase",
    component: () => import("../views/Dashboard/PurchaseView"),
    meta: { requiresAuth: true, roles: ["member"] },
  },
  {
    path: "/dashboard/bonus/",
    name: "bonus",
    component: () => import("../views/Dashboard/BonusView"),
    meta: { requiresAuth: true, roles: ["curator", "manager", "member"] },
  },
  {
    path: "/dashboard/prize/",
    name: "prize",
    component: () => import("../views/Dashboard/PrizeView"),
    meta: { requiresAuth: true, roles: ["curator", "manager", "member"] },
  },
  {
    path: "*",
    redirect: "/",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    document.getElementById("app").scrollIntoView({ behavior: "smooth" });
  },
});

router.beforeEach(async (to, _, next) => {
  const requiresAuth = to.matched.some((x) => x.meta.requiresAuth);
  const requiresGuest = to.matched.some((x) => x.meta.requiresGuest);
  const isAuth = localStorage.getItem("auth");
  const userRole = JSON.parse(localStorage.getItem("userRole"));
  if (requiresAuth && !isAuth) {
    next("/");
  } else if (requiresGuest && isAuth) {
    next("/dashboard/profile");
  } else if (requiresAuth && isAuth && to.path !== "/dashboard/profile" && !to.meta?.roles?.includes(userRole)) {
    next("/dashboard/profile");
  } else {
    next();
  }
});

export default router;
