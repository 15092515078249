<template>
  <div class="modal" :class="[getSize, setLayoutColor]">
    <div class="modal__header">
      <slot name="header" />
      <div class="modal__header_button" v-if="!hideClose">
        <MainButton
          v-if="layoutColor === 'purple'"
          :iconAfter="require('@/assets/images/close.svg')"
          :isIcon="true"
          @click="$emit('hideModal')"
        />
        <MainButton
          v-if="layoutColor === 'red'"
          :iconAfter="require('@/assets/images/close-red.svg')"
          :isIcon="true"
          @click="$emit('hideModal')"
        />
      </div>
    </div>
    <div class="modal__wrapper">
      <div class="modal__content">
        <slot name="content" />
      </div>
    </div>
  </div>
</template>

<script>
import MainButton from "../ui/Button/MainButton.vue";

export default {
  name: "ModalBox",
  components: {
    MainButton,
  },
  props: {
    size: {
      type: String,
    },
    hideClose: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    getSize() {
      return `modal_${this.size}`;
    },
    layoutColor() {
      return this.$store.getters.GET_LAYOUT_COLOR;
    },
    setLayoutColor() {
      return `modal_${this.layoutColor}`;
    },
  },
};
</script>

<style lang="scss">
.modal {
  max-height: 98vh;
  overflow-y: auto;
  position: fixed;
  max-width: 422px;
  width: 100%;
  padding: 27px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.06);
  border-radius: 20px;
  background-color: $color-white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  transition: $transition-main;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &_purple {
    & .modal__content_header_purple {
      color: $color-purple-10;
    }

    & .modal__content_subtitle_purple {
      color: $color-purple-10;
    }

    & .modal__content_input {
      border: 1px solid rgba(76, 65, 203, 0.5);
      color: $color-purple-50;

      &::placeholder {
        color: rgba(76, 65, 203, 0.5);
      }

      &_disable {
        border: 1px solid $color-red;

        &:focus {
          border: 1px solid $color-red;
        }
      }
    }

    & .modal__content_field {
      border: 1px solid rgba(76, 65, 203, 0.5);
    }

    & .modal__content_img {
      background-color: $color-purple-30;
    }

    & .modal__content_text {
      color: rgba(76, 65, 203, 0.6);

      &-email {
        color: $color-purple-40;
      }
    }

    & .modal__content_info {
      color: rgba(115, 102, 255, 0.6);

      & span {
        color: $color-purple-20;
      }
    }

    & .modal__content_country {
      & div {
        color: rgba(76, 65, 203, 0.6);
      }
    }

    & .modal__content_box {
      &_title {
        color: rgba(130, 119, 255, 0.5);
      }

      &_name {
        color: $color-purple-10;
      }

      &_item {
        &_name {
          color: $color-purple-10;
        }

        &_count {
          color: rgba(130, 119, 255, 0.5);
        }
      }
    }

    & .modal__content_pdf_link {
      color: $color-purple-20;
    }
  }

  &_red {
    & .modal__content_header_purple {
      color: $color-red-10;
    }

    & .modal__content_subtitle_purple {
      color: $color-red-10;
    }

    & .modal__content_input {
      color: $color-red-20;
      border: 1px solid rgba(176, 6, 51, 0.5);

      &::placeholder {
        color: rgba(176, 6, 51, 0.5);
      }

      &_disable {
        border: 1px solid $color-red;

        &:focus {
          border: 1px solid $color-red;
        }
      }
    }

    & .modal__content_field {
      border: 1px solid rgba(176, 6, 51, 0.5);
    }

    & .modal__content_img {
      background-color: $color-dark-yellow;
    }

    & .modal__content_text {
      color: rgba(176, 6, 51, 0.6);

      &-email {
        color: $color-red-10;
      }
    }

    & .modal__content_info {
      color: rgba(176, 6, 51, 0.6);

      & span {
        color: $color-red-20;
      }
    }

    & .modal__content_country {
      & div {
        color: #97999b;
      }
    }

    & .modal__content_box {
      &_title {
        color: rgba(176, 6, 51, 0.5);
      }

      &_name {
        color: $color-red-10;
      }

      &_item {
        &_name {
          color: $color-red-10;
        }

        &_count {
          color: rgba(176, 6, 51, 0.5);
        }
      }
    }

    & .modal__content_pdf_link {
      color: $color-red-20;
    }
  }

  &_medium {
    max-width: 532px;
    width: 100%;
  }

  &_large {
    max-width: 640px;
    width: 100%;
  }

  &_larger {
    max-width: 764px;
    width: 100%;
  }

  &_xl {
    max-width: 90vw;
  }

  &_full {
    max-height: 100%;
    min-height: 100vh;
    border-radius: 0;
    max-width: 100%;
    width: 500px;
    justify-content: flex-start;
  }

  &__wrapper {
    width: 100%;
    margin-top: auto;
    margin-bottom: auto;
  }

  &__header {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &__header_button {
    width: 15px;
    height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;

    .button {
      padding: 0;
      border: none;
      background: none;
      cursor: pointer;
      transition: $transition-main;

      &:hover {
        transform: rotate(-180deg) scale(1.2);
        transition: $transition-main;
      }
    }
  }

  &__content {
    width: 100%;
    height: 100%;
    padding: 0 33px 33px 33px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &:last-child {
      margin-bottom: 10px;
    }

    &_message {
      display: flex;
      flex-direction: column;
    }

    &_header {
      max-width: 320px;
      width: 100%;
      font-weight: 700;
      font-size: 24px;
      margin-bottom: 30px;

      &-sm {
        max-width: 310px;
        margin-bottom: 10px;
      }

      &-md {
        max-width: 350px;
      }
    }

    &_error {
      color: $color-red-20;
      font-weight: 400;
      font-size: 14px;
      margin-bottom: 20px;

      &-email {
        margin-bottom: 10px;
        font-size: 12px;
      }
    }

    &_success {
      color: $color-green;
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 20px;
    }

    &_form {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &_info {
      font-weight: 700;
      font-size: 16px;
      margin-bottom: 10px;

      // &-email {
      //   color: $color-blue;
      // }
    }

    &_pdf {
      width: 100%;
      margin-bottom: 20px;
      border: 1px solid #e0e0e0;

      &_link {
        text-align: center;
        margin-bottom: 20px;
        font-size: 18px;
        font-weight: 600;
      }
    }

    &_agreement {
      display: flex;
      justify-content: center;
      width: 100%;
      margin-bottom: 30px;
    }

    &_subheader {
      margin-bottom: 10px;
      color: $color-red;
      font-size: 16px;
      font-weight: 700;

      &-green {
        color: $color-green;
      }
    }

    &_img {
      max-width: 302px;
      width: 100%;
      height: 260px;
      border-radius: 26px;
      overflow: hidden;
      margin-bottom: 30px;
      position: relative;

      &_box {
        width: 286px;
        height: 286px;
        position: absolute;
        top: 12%;
        left: 12%;
        border-radius: 50%;
        overflow: hidden;
        background-color: $color-white;

        & img {
          width: 94%;
          height: 94%;
          object-fit: cover;
        }
      }
    }

    &_text {
      display: flex;
      flex-direction: column;
      gap: 10px;
      font-weight: 700;
      font-size: 16px;
      margin-bottom: 40px;

      &-purple {
        margin-bottom: 10px;
        color: $color-purple-10;
      }

      &-mistake {
        @include font("Inter", 14px, 500);
      }
    }

    &_subtitle {
      margin-bottom: 30px;
      font-weight: 700;
      font-size: 16px;
    }

    &_country {
      margin-bottom: 20px;

      & div {
        font-weight: 400;
        font-size: 14px;
        margin-bottom: 5px;
      }
    }

    &_inputs {
      margin-bottom: 30px;
      display: flex;
      flex-direction: column;

      &-gap {
        gap: 10px;

        & .modal__content_input {
          margin-bottom: 0;
        }
      }

      &-sm {
        margin-bottom: 10px;
      }

      &-zero {
        margin-bottom: 0;
      }
    }

    &_label {
      color: $color-red;
      margin-bottom: 10px;
      display: none;

      &-show {
        display: inline-block;
      }
    }

    &_selects {
      margin-bottom: 20px;
      gap: 20px;
      display: flex;
      flex-direction: column;
    }

    &_hint {
      @include font("Inter", 14px, 400);
      color: black;
      margin-bottom: 10px;
    }

    &_input {
      width: 100%;
      height: 48px;
      border-radius: 10px;
      padding: 0 10px;
      margin-bottom: 10px;

      &-width {
        max-width: 250px;
      }

      &::placeholder {
        font-size: 14px;
        font-weight: 400;
      }

      &_disable {
        border: 1px solid $color-red;

        &:focus {
          border: 1px solid $color-red;
        }
      }

      &_success {
        border: 1px solid $color-green;

        &:focus {
          border: 1px solid $color-green;
        }
      }
    }

    &_field {
      display: flex;
      align-items: center;
      border-radius: 10px;
      overflow: hidden;
      margin-bottom: 10px;

      & > .modal__content_icon {
        cursor: pointer;
        width: 18px;
        height: 18px;
        margin-right: 10px;

        &-hidden {
          display: none;
        }
      }

      & > .modal__content_input {
        border: none;
        margin-bottom: 0;
      }

      &_disable {
        border: 1px solid $color-red;

        &:focus {
          border: 1px solid $color-red;
        }
      }
    }

    &_icon {
      display: inline-block;
      width: 18px;
      height: 18px;
    }

    &_row {
      display: flex;
      justify-content: space-between;
      gap: 15px;

      &-mg {
        margin-bottom: 30px;
      }
    }

    &_agreement {
      display: flex;
      width: 100%;
      margin-bottom: 30px;
    }

    &_checkbox {
      max-width: 250px;
      width: 100%;
      margin-bottom: 30px;
      align-self: flex-end;
    }

    &_notification {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
      align-items: flex-start;
    }

    &_button {
      max-width: 302px;
      width: 100%;
      height: 48px;

      &-small {
        max-width: 250px;
        height: 48px;
        width: 100%;
        align-self: center;
      }

      &-feedback {
        max-width: 380px;
        margin-bottom: 16px;
      }

      &-mb {
        margin-bottom: 20px;
      }

      &-mt {
        margin-top: 20px;
      }
    }

    &_options {
      display: flex;
      justify-content: space-between;
    }

    &_textarea {
      margin-bottom: 40px;
    }

    &_form-hint {
      color: $color-grey;
      @include font("Inter", 14px, 400);
      margin-bottom: 20px;
    }

    &_form-hint-colored {
      color: $color-red;
    }

    &_container {
      display: flex;
      gap: 32px;

      &:last-child {
        margin-top: 30px;
        align-items: flex-end;
      }
    }

    &_box {
      width: 306px;
      display: flex;
      flex-direction: column;

      & .modal__content_img {
        margin-bottom: 16px;
      }

      &_name {
        font-weight: 700;
        font-size: 18px;
      }

      &_title {
        font-weight: 700;
        font-size: 16px;
        margin-bottom: 4px;
      }

      &_item {
        &:not(:last-child) {
          margin-bottom: 12px;
        }

        &_name {
          font-size: 15px;
          font-weight: 600;
        }

        &_count {
          font-size: 14px;
          font-weight: 700;
        }
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .modal {
    &_large {
      max-width: 480px;
    }

    &__content {
      &_inputs-gap {
        .modal__content_row {
          gap: 10px;
        }
      }

      &_row {
        flex-wrap: wrap;
        gap: 0;

        & .input-search-width {
          max-width: 100%;
        }

        & .select {
          max-width: 100%;
          margin-bottom: 10px;
        }
      }

      &_input {
        max-width: 100%;
      }

      &_checkbox {
        align-self: flex-start;
      }

      &_button {
        max-width: 100%;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .modal {
    &_large {
      max-width: 400px;
    }

    &_full {
      max-width: 100%;
    }
  }
}

@media screen and (max-width: 440px) {
  .modal {
    max-width: 400px;

    &_large {
      max-width: 360px;
    }

    &_full {
      max-width: 100%;
    }
  }
}

@media screen and (max-width: 390px) {
  .modal {
    max-width: 340px;

    &__content {
      padding: 0;
    }

    &_large {
      max-width: 340px;
    }

    &_full {
      max-width: 100%;
    }
  }
}

@media screen and (max-width: 346px) {
  .modal {
    max-width: 312px;

    &_large {
      max-width: 312px;
    }

    &_full {
      max-width: 100%;
    }
  }
}
</style>
