import Vue from "vue";
import Vuetify, { VDataTable, VCard, VTextField, VPagination, VIcon } from "vuetify/lib";

Vue.use(Vuetify, {
  components: {
    VDataTable,
    VCard,
    VTextField,
    VPagination,
    VIcon,
  },
});

const opts = {};

export default new Vuetify(opts);
