import axios from "axios";
import store from "@/store";
import ApiResponse from "@/api/response";
import utils from "@/assets/js/utils";

export default class Network {
  static configure() {
    axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
    axios.defaults.auth = { username: "scid", password: "scid" };
    if (localStorage.token) {
      store.commit("user/SET_TOKEN", localStorage.getItem("token"));
    }
  }

  static async request(url, method, data, config) {
    if (!utils.isPlainObject(config)) {
      config = {};
    }
    let request = {
      method: method,
      url: url,
      validateStatus: function (status) {
        return status >= 200 && status <= 500;
      },
    };
    if (data) {
      if (method === "get") {
        request.params = data;
      } else {
        request.data = data;
      }
    }
    request = { ...request, ...config };
    try {
      let result = await axios(request);
      let response;
      if (result.status === 413) {
        response = new ApiResponse({
          error: { response: [{ error_display_message: "Размер загружаемых изображений превышает допустимый" }] },
        });
      } else response = new ApiResponse(result.data);

      return response.clone();
    } catch (error) {
      if (error & error.response) {
        return new ApiResponse(error.response.data);
      }
      return new ApiResponse(error);
    }
  }

  static get(url, data, config) {
    return this.request(url, "get", data, config);
  }

  static post(url, data, config) {
    return this.request(url, "post", data, config);
  }

  static put(url, data, config) {
    return this.request(url, "put", data, config);
  }

  static patch(url, data, config) {
    return this.request(url, "patch", data, config);
  }

  static delete(url, data, config) {
    return this.request(url, "delete", data, config);
  }
}
