<template>
  <ModalBox @hideModal="$emit('hideModal')">
    <template #content>
      <div class="modal__content_message">
        <div class="modal__content_header modal__content_header_purple">Авторизация</div>
        <div v-if="error" class="modal__content_error">
          {{ error }}
        </div>
        <form action="" enctype="multipart/form-data" ref="loginForm" @submit.prevent="login">
          <div class="modal__content_inputs modal__content_inputs-sm">
            <input
              class="modal__content_input"
              type="tel"
              name="login"
              id="login"
              v-model="loginForm.login"
              placeholder="Логин"
              required="true"
              @input="formatPhone"
            />
            <div class="modal__content_field">
              <input
                class="modal__content_input"
                type="password"
                name="password"
                id="password"
                v-model="loginForm.password"
                placeholder="Пароль"
                required="true"
              />
            </div>
          </div>
          <div class="modal__content_button modal__content_button-mb">
            <MainButton
              title="Авторизация"
              type="submit"
              color="purple"
              :isDisabled="!(loginForm.login && loginForm.password)"
              :isLoading="isLoading"
            />
          </div>
        </form>
        <div class="modal__content_options">
          <div>
            <MainButton title="Забыли пароль?" color="transparent" @click="$emit('toRecovery')" />
          </div>
          <div>
            <MainButton title="Зарегистрироваться" color="transparent" @click="$emit('toRegister')" />
          </div>
        </div>
      </div>
    </template>
  </ModalBox>
</template>

<script>
import ModalBox from "@/components/template/ModalBox.vue";
import MainButton from "@/components/ui/Button/MainButton.vue";

export default {
  name: "LoginForm",
  components: {
    ModalBox,
    MainButton,
  },
  data() {
    return {
      loginForm: {
        login: null,
        password: null,
      },
      isLoading: false,
    };
  },
  computed: {
    error() {
      return this.$store.getters["user/GET_ERROR"];
    },
    isAuth() {
      return this.$store.getters["user/GET_IS_AUTH"];
    },
  },
  methods: {
    async login() {
      this.isLoading = true;
      let payload = {
        login: this.loginForm.login.replace(/[^A-Z0-9]/gi, ""),
        password: this.loginForm.password,
      };
      await this.$store.dispatch("user/login", payload);
      if (this.isAuth && !this.error) {
        this.resetForm();
        this.$emit("hideModal");
        this.$router.push("/dashboard/profile/");
      }
      this.isLoading = false;
    },
    resetForm() {
      this.loginForm.login = this.loginForm.password = null;
    },
    formatPhone(event) {
      let blank = "+_ (___) ___-__-__";
      let i = 0;
      let val;
      if (event.target.value[0] === "9") {
        val = event.target.value.replace(/\D/g, "").replace(/^/, "7");
      } else {
        val = event.target.value.replace(/\D/g, "").replace(/^\d/, "7");
      }
      this.loginForm.login = blank.replace(/./g, function (char) {
        if (/[_\d]/.test(char) && i < val.length) return val.charAt(i++);
        return i >= val.length ? "" : char;
      });
    },
  },
};
</script>
