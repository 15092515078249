<template>
  <div class="input-search" :class="[getWidth, setLayoutColor]">
    <label class="input-search__label" :for="name" v-if="titleLabel">
      {{ titleLabel }}
    </label>
    <input
      class="input-search__field"
      :type="type"
      :placeholder="placeholder"
      :name="name"
      :id="id"
      :value="value"
      :disabled="disabled"
      :required="required"
      ref="input"
      @input="emitValue(valueInput)"
    />
    <div class="input-search__options" v-if="showOptions && name === 'curator'">
      <ul v-for="company in companyList" :key="company.curator_id">
        <li class="input-search__option" @click="chooseCurator(company)">
          {{ company.legal_name_organization }}
        </li>
      </ul>
    </div>
    <div class="input-search__options" v-if="showOptions && name === 'company_inn'">
      <ul v-for="(item, index) in innList" :key="'G' + index">
        <li class="input-search__option" @click="chooseInn(item)">{{ item.tin }} - {{ item.name }} - {{ item.address }}</li>
      </ul>
    </div>
    <div class="input-search__options" v-if="showOptions && name === 'city' && cityList">
      <ul v-for="(item, index) in cityList" :key="'M' + index">
        <li class="input-search__option" @click="chooseCity(item)">{{ item }}</li>
      </ul>
    </div>
    <div class="input-search__options" v-if="showOptions && name === 'street' && streetList">
      <ul v-for="(item, index) in streetList" :key="'N' + index">
        <li class="input-search__option" @click="chooseStreet(item)">{{ item.city }}, {{ item.street }}</li>
      </ul>
    </div>
    <div class="input-search__options" v-if="showOptions && name === 'company_actual_address_organization' && regionList">
      <ul v-for="(item, index) in regionList" :key="'N' + index">
        <li class="input-search__option" @click="chooseRegion(item)">{{ item }}</li>
      </ul>
    </div>
    <div class="input-search__options" v-if="showOptions && name === 'retail_inn'">
      <ul>
        <li v-if="retailList" class="input-search__option">
          {{ retailList.fio }} - {{ retailList.company_info.legal_name_organization }} -
          {{ retailList.company_info.legal_address_organization }} - {{ retailList.user_phone }} - {{ retailList.user_email }} -
          {{ retailList.user_left_project_points }} баллов
        </li>
        <li v-else class="input-search__option">No matching records found</li>
      </ul>
    </div>
    <div class="input-search__options" v-if="showOptions && name === 'manager'">
      <ul v-for="(user, index) in managerList" :key="'H' + index">
        <li class="input-search__option" @click="chooseManager(user)">
          {{ user.name }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "InputSearch",
  props: {
    titleLabel: {
      type: String,
    },
    type: {
      type: String,
      default: "text",
    },
    placeholder: {
      type: String,
    },
    name: {
      type: String,
    },
    value: {
      type: [String, Number],
    },
    required: {
      type: String,
    },
    id: {
      type: String,
    },
    setWidth: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
  },
  data() {
    return {
      valueInput: "",
      showOptions: false,
    };
  },
  computed: {
    getWidth() {
      return this.setWidth ? "input-search-width" : "";
    },
    layoutColor() {
      return this.$store.getters.GET_LAYOUT_COLOR;
    },
    setLayoutColor() {
      return `input-search_${this.layoutColor}`;
    },
    innList() {
      return this.$store.getters["general/GET_INN_LIST"];
    },
    companyList() {
      return this.$store.getters["general/GET_COMPANY_LIST"];
    },
    retailList() {
      return this.$store.getters["general/GET_RETAIL_LIST"];
    },
    cityList() {
      return this.$store.getters["general/GET_CITY_LIST"];
    },
    streetList() {
      return this.$store.getters["general/GET_STREET_LIST"];
    },
    regionList() {
      return this.$store.getters["general/GET_REGION_LIST"];
    },
  },
  watch: {
    value: function () {
      if (this.value === null) {
        this.valueInput = "";
      }
    },
  },
  methods: {
    emitValue() {
      let input = this.$refs.input;
      if (input.value.length > 0) {
        this.showOptions = true;
      } else {
        this.showOptions = false;
      }
      this.$emit("input", input.value);
    },
    chooseInn(item) {
      this.valueInput = item.tin;
      this.$emit("input", this.valueInput);
      this.showOptions = false;
      this.$emit("legalData", { name: item.name, address: item.address });
    },
    chooseCity(item) {
      this.valueInput = item;
      this.$emit("input", this.valueInput);
      this.showOptions = false;
      this.$store.commit[("general/SET_CITY_LIST", null)];
      this.$emit("insertCity", item);
    },
    chooseStreet(item) {
      this.valueInput = item.street;
      this.$emit("input", this.valueInput);
      this.showOptions = false;
      this.$store.commit[("general/SET_STREET_LIST", null)];
      this.$emit("insertStreet", item.street);
    },
    chooseRegion(item) {
      this.valueInput = item;
      this.$emit("input", this.valueInput);
      this.showOptions = false;
      this.$emit("regionData", item);
    },
    chooseCurator(company) {
      this.valueInput = company.legal_name_organization;
      this.$emit("id", company.curator_id);
      this.$emit("input", this.valueInput);
      this.showOptions = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.input-search {
  width: 100%;
  position: relative;

  &_purple {
    & .input-search__field {
      border: 1px solid rgba(76, 65, 203, 0.5);
      color: $color-purple-50;

      &::placeholder {
        color: rgba(76, 65, 203, 0.5);
      }

      &_disable {
        border: 1px solid $color-red;

        &:focus {
          border: 1px solid $color-red;
        }
      }
    }

    & .input-search__option {
      &:hover {
        color: $color-purple-20;
      }
    }

    & .input-search__label {
      color: $color-purple-20;
    }
  }

  &_red {
    & .input-search__field {
      border: 1px solid rgba(176, 6, 51, 0.5);
      color: $color-red-20;

      &::placeholder {
        color: rgba(176, 6, 51, 0.5);
      }

      &_disable {
        border: 1px solid $color-red;

        &:focus {
          border: 1px solid $color-red;
        }
      }
    }

    & .input-search__option {
      &:hover {
        color: $color-red-20;
      }
    }

    & .input-search__label {
      color: $color-red-20;
    }
  }

  &-width {
    max-width: 250px;
  }

  &__label {
    margin-bottom: 10px;
    color: $color-purple-50;
    font-size: 16px;
    font-weight: 700;
    display: inline-block;
  }

  &__field {
    width: 100%;
    height: 48px;
    border-radius: 10px;
    padding: 0 10px;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 10px;

    &::placeholder {
      font-size: 14px;
      font-weight: 400;
    }

    &_disable {
      border: 1px solid $color-red;

      &:focus {
        border: 1px solid $color-red;
      }
    }
  }

  &__options {
    transition: $transition-main;
    position: absolute;
    top: 50px;
    overflow-x: auto;
    width: 100%;
    max-height: 200px;
    overflow-y: scroll;
    background-color: $color-white;
    box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 12px;
    padding-bottom: 12px;
    z-index: 4;
    border-radius: 10px;
  }

  &__option {
    position: relative;
    padding-left: 12px;
    font-size: 14px;
    font-weight: 400;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 573px) {
  .input-search {
    &-width {
      max-width: 100%;
    }
  }
}
</style>
