<template>
  <ModalBox size="xl" :hideClose="true">
    <template #content>
      <div class="modal__content_message">
        <div class="modal__content_header">Правила акции</div>
        <div v-if="isAndroid" class="modal__content_pdf_link">
          <a :href="pdfUrl" target="_blank">Ознакомиться с Правилами акции</a>
        </div>
        <div v-else class="modal__content_pdf">
          <object :data="pdfUrl" type="application/pdf" width="100%" height="600px">
            <iframe :src="`https://docs.google.com/gview?url=${pdfUrl}&embedded=true`" style="width: 100%; height: 600px"></iframe>
          </object>
        </div>
        <div class="modal__content_agreement">
          <CheckboxBox name="termsAgreement" :justifyLeft="true" v-model="agreement" text="Я согласен с правилами акции" />
        </div>
        <div class="modal__content_button-small">
          <MainButton title="Принять участие" color="purple" :isLoading="isLoading" :isDisabled="!agreement" @click="acceptAgreement" />
        </div>
      </div>
    </template>
  </ModalBox>
</template>

<script>
import ModalBox from "@/components/template/ModalBox.vue";
import MainButton from "@/components/ui/Button/MainButton.vue";
import CheckboxBox from "@/components/ui/Checkbox/CheckboxBox.vue";

export default {
  name: "TermsModal",
  components: {
    ModalBox,
    MainButton,
    CheckboxBox,
  },
  data() {
    return {
      agreement: false,
      isLoading: false,
    };
  },
  computed: {
    userRole() {
      return this.$store.getters["user/GET_USER_ROLE"];
    },
    termsClosed() {
      return this.$store.getters.GET_TERMS_CLOSE;
    },
    userProfile() {
      return this.$store.getters["user/GET_CURRENT_USER"];
    },
    isAndroid() {
      return /android/i.test(navigator.userAgent) ? true : false;
    },
    pdfUrl() {
      if (this.userRole === "curator") {
        return "//bonus.brixogroup.com/upload/pdf/BRIXO_BONUS_Curator_rules.pdf";
      } else if (this.userRole === "manager") {
        return "//bonus.brixogroup.com/upload/pdf/BRIXO_BONUS_Sales_rules.pdf";
      } else if (this.userRole === "member" && this.userProfile && !this.userProfile.is_dealer) {
        return "//bonus.brixogroup.com/upload/pdf/BRIXO_BONUS_Retail_rules.pdf";
      } else if (this.userRole === "member" && this.userProfile && this.userProfile.is_dealer) {
        return "//bonus.brixogroup.com/upload/email/BRIXO_BONUS_Dealer_rules.pdf";
      }
      return "";
    },
  },
  methods: {
    async acceptAgreement() {
      this.isLoading = true;
      let res = await this.$api.user.acceptAgreement();
      if (res.isSuccess) {
        this.$emit("hideTerms");
      }
      this.isLoading = false;
    },
  },
};
</script>
