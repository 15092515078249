<template>
  <div class="footer" :class="setLayoutColor">
    <div class="footer__info">
      <div class="footer__logo">
        <router-link to="/" class="logo" @click.native="scrollToTop">
          <img src="@/assets/images/brixo-logo.svg" alt="BRIXO" />
        </router-link>
      </div>
      <div class="footer__website">
        <span>Официальный сайт</span>
        <a href="https://www.brixogroup.com/" target="_blank"> www.brixogroup.com </a>
      </div>
      <div class="footer__address">
        <div class="footer__address_office">
          <div class="footer__address_title">Офис в Москве</div>
          <div class="footer__address_subtitle">Мамоновский пер., д.4, стр.1 Москва, 123001, Россия</div>
        </div>
        <div class="footer__address_office">
          <div class="footer__address_title">Офис во Владивостоке</div>
          <div class="footer__address_subtitle">пр-кт Красного Знамени, 82В, офис 208, Владивосток, 690014, Россия</div>
        </div>
      </div>
      <div class="footer__support">
        <div class="footer__support_title">Техническая поддержка</div>
        <div class="footer__support_button">
          <MainButton
            v-if="layoutColor === 'purple'"
            title="Любой вопрос или проблема"
            color="yellow"
            :iconBefore="require('@/assets/images/mail.svg')"
            @click="$emit('openFeedback')"
          />
          <MainButton
            v-else
            title="Любой вопрос или проблема"
            color="yellow"
            :iconBefore="require('@/assets/images/mail-red.svg')"
            @click="$emit('openFeedback')"
          />
        </div>
      </div>
    </div>
    <div class="footer__info_mobile">
      <div class="footer__info_container">
        <div class="footer__logo">
          <router-link to="/" class="logo" @click.native="scrollToTop">
            <img src="@/assets/images/brixo-logo.svg" alt="BRIXO" />
          </router-link>
        </div>
        <div class="footer__address">
          <div class="footer__address_office">
            <div class="footer__address_title">Офис в Москве</div>
            <div class="footer__address_subtitle">Мамоновский пер., д.4, стр.1 Москва, 123001, Россия</div>
          </div>
          <div class="footer__address_office">
            <div class="footer__address_title">Офис во Владивостоке</div>
            <div class="footer__address_subtitle">пр-кт Красного Знамени, 82В, офис 208, Владивосток, 690014, Россия</div>
          </div>
        </div>
      </div>
      <div class="footer__info_container">
        <div class="footer__website">
          <span>Официальный сайт</span>
          <a href="https://www.brixogroup.com/" target="_blank"> www.brixogroup.com </a>
        </div>
        <div class="footer__support">
          <div class="footer__support_title">Техническая поддержка</div>
          <div class="footer__support_button">
            <MainButton
              v-if="layoutColor === 'purple'"
              title="Любой вопрос или проблема"
              color="yellow"
              :iconBefore="require('@/assets/images/mail.svg')"
              @click="$emit('openFeedback')"
            />
            <MainButton
              v-else
              title="Любой вопрос или проблема"
              color="yellow"
              :iconBefore="require('@/assets/images/mail-red.svg')"
              @click="$emit('openFeedback')"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="footer__contact">
      <div class="footer__phone">
        <!-- <div class="footer__phone_number">8 800 302 23 72</div>
        <div class="footer__phone_subtitle">Единая линия для всех клиентов 24/7</div> -->

        <div class="footer__social_title">Вопросы? Нужна помощь?</div>
        <div class="footer__phone_subtitle">Пишите – <a href="mailto:bonus@brixogroup.com">bonus@brixogroup.com</a></div>
      </div>
      <div class="footer__social">
        <a href="https://vk.com/sakurafiltresandradiators" target="_blank">
          <img src="@/assets/images/vkontakte.svg" alt="Вконтакте" />
        </a>
        <div class="footer__social_title">Мы в социальных сетях</div>
      </div>
    </div>
  </div>
</template>

<script>
import MainButton from "@/components/ui/Button/MainButton.vue";

export default {
  name: "FooterBar",
  components: {
    MainButton,
  },
  computed: {
    layoutColor() {
      return this.$store.getters.GET_LAYOUT_COLOR;
    },
    setLayoutColor() {
      return `footer_${this.layoutColor}`;
    },
  },
  methods: {
    scrollToTop() {
      document.getElementById("app").scrollIntoView({ behavior: "smooth" });
    },
  },
};
</script>

<style lang="scss" scoped>
.footer {
  width: 100%;
  height: 100%;

  &_purple {
    & .footer__website {
      & a {
        color: $color-yellow;
      }
    }

    & .footer__social_title {
      & a {
        color: $color-yellow;
      }
    }
  }

  &_red {
    & .footer__website {
      & a {
        color: $color-dark-yellow;
      }
    }

    & .footer__social_title {
      & a {
        color:  $color-dark-yellow;
      }
    }
  }

  &__info {
    display: flex;
    padding-bottom: 40px;
    border-bottom: 1px solid $color-light-grey;

    &_mobile {
      display: none;
      padding-bottom: 40px;
      border-bottom: 1px solid $color-light-grey;
    }
  }

  &__logo {
    margin-right: 72px;
  }

  &__website {
    margin-right: 148px;

    & span {
      display: block;
      margin-bottom: 24px;
      font-weight: 700;
    }

    & a {
      display: block;
      font-weight: 500;
    }
  }

  &__address {
    width: 248px;
    margin-right: 148px;

    &_office {
      &:first-child {
        margin-bottom: 24px;
      }
    }

    &_title {
      font-weight: 700;
      margin-bottom: 12px;
    }
  }

  &__support {
    &_title {
      margin-bottom: 24px;
      font-weight: 700;
    }

    &_button {
      width: 274px;
      height: 44px;
    }
  }

  &__contact {
    display: flex;
    gap: 148px;
    justify-content: space-between;
    padding-top: 22px;
  }

  &__phone {
    &_number {
      font-size: 44px;
      font-weight: 700;
      margin-bottom: 14px;
    }

    &_subtitle {
      font-weight: 500;
    }
  }

  &__social {
    display: flex;
    gap: 10px;

    &_title {
      font-weight: 700;
      padding-top: 4px;
      font-size: 18px;
    }
  }
}

@media screen and (max-width: 1290px) {
  .footer {
    &__info {
      display: none;

      &_mobile {
        display: flex;
        gap: 92px;
      }

      &_container {
        display: flex;
        flex-direction: column;
        height: 100%;
        align-content: space-between;

        & .footer__logo {
          margin-bottom: 52px;
        }

        & .footer__website {
          margin-bottom: 130px;
        }
      }
    }

    &__contact {
      justify-content: flex-start;
      gap: 154px;
    }
  }
}

@media screen and (max-width: 970px) {
  .footer {
    &__info {
      &_mobile {
        display: flex;
        flex-wrap: wrap;
      }
    }
  }
}

@media screen and (max-width: 839px) {
  .footer {
    &__info {
      &_mobile {
        display: flex;
        gap: 24px;
      }

      &_container {
        width: 100%;

        & .footer__logo {
          margin-bottom: 24px;
          margin-right: 0;
        }

        & .footer__website {
          margin-bottom: 0;
        }

        &:last-child {
          flex-direction: row;
          gap: 20px;
        }
      }
    }

    &__contact {
      gap: 68px;
    }

    &__address {
      width: 100%;
      display: flex;
      gap: 50px;
      margin-right: 0;

      &_office {
        width: 272px;
      }
    }

    &__phone {
      &_number {
        font-size: 32px;
      }
    }
  }
}

@media screen and (max-width: 645px) {
  .footer {
    &__address {
      flex-direction: column;
      gap: 24px;

      &_office {
        max-width: 600px;
        width: 100%;

        &:first-child {
          margin-bottom: 0;
        }
      }
    }

    &__info {
      &_container {
        &:last-child {
          flex-direction: column;
          gap: 28px;
        }
      }
    }

    &__website {
      margin-right: 0;

      & span {
        margin-bottom: 16px;
      }
    }

    &__support {
      &_title {
        margin-bottom: 14px;
      }
    }

    &__contact {
      flex-direction: column;
      gap: 22px;
    }

    &__phone {
      &_number {
        margin-bottom: 0;
      }
    }

    &__social {
      &_title {
        margin-bottom: 16px;
      }
    }
  }
}
</style>
