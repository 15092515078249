<template>
  <div id="app">
    <div class="layout" :class="[setLayoutColor, showPicture]" data-app>
      <div class="layout__header">
        <HeaderBar @login="openLogin" @logout="logout" />
      </div>
      <div class="layout__main">
        <router-view />
      </div>
      <div class="layout__footer">
        <FooterBar @openFeedback="openFeedback" />
      </div>
      <div class="layout__modal" :class="{ layout__modal_active: showModal && !isAuth }">
        <div class="layout__modal_overlay" @click="hideModal"></div>
        <LoginForm v-if="modalType === 'login'" @hideModal="hideModal" @toRecovery="toRecovery" @toRegister="toRegister" />
        <PasswordForm
          v-else-if="modalType === 'recovery'"
          :checkword="checkword"
          :email="email"
          @hideModal="hideModal"
          @toLogin="toLogin"
        />
        <RegisterForm
          v-else-if="modalType === 'register'"
          :inviteInfo="inviteInfo"
          :referralInfo="referralInfo"
          :inviteInfoID="inviteInfoID"
          :showBoth="showBoth"
          :role="registerRole"
          @hideModal="hideModal"
          @toActivation="toActivation"
        />
      </div>
      <div class="layout__modal" :class="{ layout__modal_active: showTerms || showActivation || showNotification }">
        <div class="layout__modal_overlay"></div>
        <TermsModal v-if="showTerms" @hideTerms="hideTerms" />
        <ActivationForm
          v-if="showActivation"
          :phone="loginPhone"
          @showNotification="showNotification = true"
          @hideModal="showActivation = false"
        />
        <ModalNotification v-if="showNotification" @hideModal="showNotification = false" />
      </div>
      <div class="layout__modal layout__modal_aside" :class="{ layout__modal_active: showFeedback }">
        <div class="layout__modal_overlay" @click="showFeedback = false"></div>
        <FeedbackForm @hideFeedback="showFeedback = false" />
      </div>
      <div class="preloader" v-if="preloader > 0">
        <PreloaderIcon />
      </div>
      <CookieModal v-if="showCookies" @acceptCookies="acceptCookies" />
    </div>
  </div>
</template>

<script>
import FooterBar from "@/components/template/FooterBar.vue";
import HeaderBar from "@/components/template/HeaderBar.vue";
import LoginForm from "@/components/template/LoginForm.vue";
import RegisterForm from "@/components/template/RegisterForm.vue";
import PasswordForm from "@/components/template/PasswordForm.vue";
import FeedbackForm from "@/components/template/FeedbackForm.vue";
import PreloaderIcon from "@/components/template/PreloaderIcon.vue";
import TermsModal from "@/components/template/TermsModal.vue";
import CookieModal from "@/components/template/CookieModal.vue";
import ActivationForm from "@/components/template/ActivationForm.vue";
import ModalNotification from "@/components/template/ModalNotification.vue";

export default {
  name: "App",
  components: {
    HeaderBar,
    FooterBar,
    LoginForm,
    RegisterForm,
    PasswordForm,
    FeedbackForm,
    PreloaderIcon,
    TermsModal,
    CookieModal,
    ActivationForm,
    ModalNotification,
  },
  data() {
    return {
      showModal: false,
      showFeedback: false,
      showTerms: false,
      showBoth: false,
      showActivation: false,
      modalType: "login",
      title: "brixo bonus",
      inviteInfo: null,
      referralInfo: null,
      checkword: null,
      email: null,
      inviteInfoID: null,
      registerRole: null,
      showCookies: true,
      loginPhone: null,
      showNotification: false,
    };
  },
  created() {
    this.init();
  },
  async mounted() {
    this.showCookies = !sessionStorage.cookies_accepted;
    if (window.location.href.indexOf("invite") > -1) {
      let url = new URL(window.location.href);
      this.inviteInfoID = url.searchParams.get("invite");
      if (!this.isAuth) {
        let res = await this.$api.invite.getInviteInfo(this.inviteInfoID);
        this.inviteInfo = res.result;
        this.showBoth = false;
        this.showModal = true;
        this.modalType = "register";
      }
    } else if (window.location.href.indexOf("referral") > -1) {
      let url = new URL(window.location.href);
      let id = url.searchParams.get("referral");
      let role = url.searchParams.get("role");
      if (!this.isAuth) {
        let res = await this.$api.invite.getReferralInfo(id);
        this.referralInfo = res.result;
        this.showBoth = false;
        this.registerRole = role;
        this.showModal = true;
        this.modalType = "register";
      }
    } else if (window.location.href.indexOf("check_word") > -1 && window.location.href.indexOf("email") > -1) {
      let url = new URL(window.location.href);
      this.checkword = url.searchParams.get("check_word");
      this.email = url.searchParams.get("email");
      this.showModal = true;
      this.modalType = "recovery";
    }
  },
  computed: {
    layoutColor() {
      return this.$store.getters.GET_LAYOUT_COLOR;
    },
    setLayoutColor() {
      return `layout_${this.layoutColor}`;
    },
    showPicture() {
      if (this.$route.name === "Home") {
        return `layout_${this.layoutColor}_picture`;
      }
      return null;
    },
    preloader() {
      return this.$store.getters.GET_PRELOADER;
    },
    isAuth() {
      return this.$store.getters["user/GET_IS_AUTH"];
    },
    openTerms() {
      return this.$store.getters["user/GET_SHOW_TERMS"];
    },
    userRole() {
      return JSON.parse(localStorage.getItem("userRole"));
    },
    userProfile() {
      return this.$store.getters["user/GET_CURRENT_USER"];
    },
    notSystem() {
      return !this.userProfile.is_system;
    },
  },
  watch: {
    openTerms: function () {
      if (this.openTerms === true) {
        this.showTerms = true;
      }
    },
  },
  methods: {
    init() {
      document.title = this.title;
      this.$store.dispatch("initMainPage");
      this.scrollTop();
    },
    scrollTop() {
      document.getElementById("app").scrollIntoView({ behavior: "smooth" });
    },
    async logout() {
      await this.$store.dispatch("user/logout");
      if (!this.isAuth) {
        this.$router.push("/");
      }
    },
    hideModal() {
      this.showModal = false;
      this.$store.dispatch("user/resetError");
    },
    async hideTerms() {
      this.showTerms = false;
      await this.$store.dispatch("user/fetchUser");
      if (this.userRole === "member" && !this.notSystem) {
        this.$store.dispatch("user/openSystemCurator", true);
      }
    },
    toRecovery() {
      this.$store.dispatch("user/resetError");
      this.modalType = "recovery";
    },
    toLogin() {
      this.$store.dispatch("user/resetError");
      this.modalType = "login";
    },
    toRegister() {
      this.$store.dispatch("user/resetError");
      this.showBoth = true;
      this.modalType = "register";
    },
    toActivation(payload) {
      this.hideModal();
      this.loginPhone = payload.phone;
      this.registerRole = payload.user_role;
      this.showActivation = true;
    },
    openLogin() {
      this.modalType = "login";
      this.showModal = true;
    },
    openFeedback() {
      this.showFeedback = true;
    },
    acceptCookies() {
      sessionStorage.setItem("cookies_accepted", true);
      this.showCookies = false;
    },
  },
};
</script>

<style lang="scss">
.layout {
  min-height: 100vh;
  @include font("CeraPro", 14px, 400);
  color: $color-white;

  &_purple {
    background-color: $color-purple-10;

    &_picture {
      background: $color-purple-10 url("@/assets/images/rings-purple.svg") no-repeat scroll;
    }

    & .layout__header {
      background-color: rgba(130, 119, 255, 0.8);
    }

    & .layout__footer {
      background-color: $color-purple-20;
    }
  }

  &_red {
    background-color: $color-red-10;

    &_picture {
      background: $color-red-10 url("@/assets/images/rings-red.svg") no-repeat scroll;
    }

    & .layout__header {
      background-color: rgba(176, 6, 51, 0.8);
    }

    & .layout__footer {
      background-color: $color-red-30;
    }
  }

  &__header {
    width: 100%;
    height: 158px;
    backdrop-filter: blur(8px);
    position: fixed;
    z-index: 3;
  }

  &__main {
    padding-top: 158px;
    width: 100%;
    min-height: calc(100vh - 435px);
  }

  &__footer {
    width: 100%;
    padding: 70px 100px 42px;
  }

  &__modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 4;
    opacity: 0;
    visibility: hidden;
    height: 100vh;
    transition: $transition-main;
    overflow: hidden;
  }

  &__modal_overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    background-color: rgba(16, 24, 32, 0.8);
    transition: $transition-main;
    z-index: -1;
  }

  &__modal_active {
    visibility: visible;
    opacity: 1;

    .layout__modal_overlay {
      opacity: 1;
    }

    .modal {
      transition: $transition-main;
    }
  }

  &__modal_aside {
    justify-content: flex-end;
  }
}

.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 6;
  background-color: #ffffff99;
  animation: preloader 0.5s ease forwards;
}

@keyframes preloader {
  from {
    backdrop-filter: blur(0x);
    opacity: 0;
  }
  to {
    backdrop-filter: blur(8px);
    opacity: 1;
  }
}

@media screen and (max-width: 1360px) {
  .layout {
    &__header {
      height: 102px;
    }
  }
}

@media screen and (max-width: 970px) {
  .layout {
    &_purple {
      &_picture {
        background: none;
        background-color: $color-purple-10;
      }
    }

    &_red {
      &_picture {
        background: none;
        background-color: $color-red-10;
      }
    }

    &__footer {
      padding: 40px 30px 24px;
    }
  }
}
</style>
