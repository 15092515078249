<template>
  <ModalBox @hideModal="$emit('hideModal')">
    <template #content>
      <div class="modal__content_message">
        <div class="modal__content_header modal__content_header_purple">Уведомление</div>
        <div class="modal__content_text">
          Для завершения регистрации необходимо осуществить вход по логину и паролю, направленным на ваш e-mail, и подтвердить правила
          программы. Только после этого вам будут начисляться баллы.
        </div>
        <div class="modal__content_button modal__content_button-zero">
          <MainButton title="Закрыть" @click="$emit('hideModal')" size="small" color="purple" />
        </div>
      </div>
    </template>
  </ModalBox>
</template>

<script>
import ModalBox from "@/components/template/ModalBox.vue";
import MainButton from "@/components/ui/Button/MainButton.vue";

export default {
  name: "ModalWarning",
  components: {
    ModalBox,
    MainButton,
  },
};
</script>
