import network from "@/api/network";

export default class Profile {
  static async exportMembers(config) {
    return await network.get("user/profile/export/members", null, config);
  }

  static async exportManagers(config) {
    return await network.get("user/profile/export/managers", null, config);
  }

  static async addMemberCompany(data) {
    return await network.post("user/profile/add/company", data, {});
  }

  static async addMemberCurator(data) {
    return await network.post("user/profile/add/relation", data, {});
  }

  static async checkWrongAddressCompany() {
    return await network.get("user/companies/empty/regions", null, {});
  }

  static async changeWrongAddressCompany(data) {
    return await network.put("user/companies/actualize/region", data, {});
  }

  static async removeCuratorOrSales(data) {
    return await network.post("user/profile/remove/relation", data, {});
  }
}
