import general from "./modules/general";
import content from "./modules/content";
import user from "./modules/user";
import profile from "./modules/profile";
import prize from "./modules/prize";
import invite from "./modules/invite";
import reports from "./modules/reports";
import transactions from "./modules/transactions";

class Api {
  constructor() {
    this.general = general;
    this.content = content;
    this.user = user;
    this.profile = profile;
    this.prize = prize;
    this.invite = invite;
    this.reports = reports;
    this.transactions = transactions;
  }
}

export default new Api();
