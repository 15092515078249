import network from "@/api/network";

export default class Reports {
  static async getReports(page) {
    return await network.get(`deals/report/titles?page=${page}&per_page=10`, null, {});
  }

  static async getReportDetails(file_id) {
    return await network.get(`deals/report/details?file_id=${file_id}&page=1&per_page=9999`, null, {});
  }

  static async downloadReport(id, config) {
    return await network.get(`deals/report/download/file/${id}`, null, config);
  }

  static async uploadCuratorReport(data) {
    return await network.post("deals/report/import", data, {});
  }

  static async exportTinList(config) {
    return await network.get("user/profile/curator/export/inn/members", null, config);
  }
}
